//NOTE: in bridge-dev-mode %BRIDGE-URL%/pwa/content coder can look up available localizations
import dotty from 'dotty';
import striptags from 'striptags';

class Localizator {
  constructor(){
    this._data = {};
  }

  set data(data) {
    this._data = data;
  }

  getLocale(key, exchange = {}, removeTags = false) {
    let keys = Object.keys(exchange);
    let loc = dotty.get(this._data, key);
    if (keys.length === 0) return removeTags ? JSON.parse(striptags(JSON.stringify(loc))) : loc;
    loc = JSON.stringify(loc);
    for (let i = 0; i < keys.length; i++) {
      loc = loc.split(`#${keys[i].toUpperCase()}#`).join(exchange[keys[i]]);
    }
    return JSON.parse(removeTags ? striptags(loc) : loc);
  }
}

export default new Localizator();
