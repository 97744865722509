import React, {Component} from 'react';
import MCPOverlord from './../utils/MCPOverlord';

const FULLCIRCLE = 314.159;
const MODES = ['tracker', 'timer', 'timer advanced', 'proceed', 'register', 'tutorial', 'interimScreen'];

class MCP extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      mode: props.mode,
      overlordDisable: false,
    };
    this._timefield = React.createRef();
    this._keyHandler = this.onKeyUp.bind(this);
  }

  componentDidMount() {
    MCPOverlord.register(this);
    if (this.props.handleKey) {
      document.addEventListener('keyup', this._keyHandler);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this._keyHandler);
    MCPOverlord.deregister(this);
  }

  get disabled() {
    let isDisabled = this.props.disabled || this.state.overlordDisable;
    return isDisabled === undefined ? false : isDisabled;
  }

  onKeyUp(e) {
    if (e.keyCode !== 13) return;
    if (!this.disabled && this.props.onClick) this.props.onClick();
  }

  beingOverlorded(disabled){
    this.setState({overlordDisable: disabled});
  }

  pad = (n) => ('0' + n).slice(-2);

  showTime(value) {
    value = value/1000;
    let minutes = Math.floor(value / 60);
    let seconds = this.pad(Math.round(value - minutes * 60));
    let output = `${minutes}:${seconds}`;
    this._timefield.current.innerHTML = output;
  }

  setMode(strg) {
    if (strg === this.state.mode) return;
    if (!MODES.includes(strg)) return;
    this.setState({mode: strg});
  }

  getMode() {
    return this.state.mode;
  }

  render() {
    let classes = ['mcp'];
    if (this.state.mode) classes.push(this.state.mode);
    if (this.disabled) classes.push('disabled');
    return (
      <div className={classes.join(' ')}>
        <button disabled={this.disabled} onClick={this.props.onClick}>
          <span ref={this._timefield} id="time" className="time"></span>
          <svg id="MCP" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 113 113">
            <title>MasterControlProgram</title>
            <circle className="background" cx="56.5" cy="56.5" r="56.5"/>
            <circle className="ring" cx="56.5" cy="56.5" r="42.8"/>
            <circle className="track" cx="56.5" cy="56.5" r="50"/>
            <circle strokeDashoffset={(1 - this.props.progress) * FULLCIRCLE} className="progress" cx="56.5" cy="56.5" r="50"/>
            <g id="status" className="status">

              <g id="play" className="blue">
                <path d="M47.54,40.52,75,58,47.54,75.44Z"/>
              </g>

              <g id="double" className="blue stroked">
                <path d="M55.66,41.24l27,17.17-27,17.17Z"/>
                <path d="M36.83,41.24l27,17.17-27,17.17Z"/>
              </g>

              <g id="double_up" className="blue stroked">
                <path d="M55.73,24.43,73.55,35.78,55.73,47.13Z"/>
                <path d="M43.15,24.4,61,35.75,43.15,47.1Z"/>
              </g>

            </g>
          </svg>
        </button>
      </div>
    )
  }
}

export default MCP;
