import React, { Component } from 'react';
import LocElement from './LocElement';
import Localizator from './../utils/Localizator';
import Overlay from './Overlay';
import StaticContent from './../views/StaticContent';
import User from './../User';

class CookieBanner extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      overlaySlug: null,
      panelHide: false
    }
    this.panel = React.createRef();
  }

  onLinkClick() {
    this.setState({ overlaySlug: 'datenschutz', panelHide: true })
  }

  onConsentClick() {
    User.setDataEntry('consent.cookies', true);
    this.props.onConsent && this.props.onConsent();
  }

  panelUnhide() {
    this.setState({panelHide: false});
  }

  render() {
    let data = Localizator.getLocale('cookie_consent');
    let panelClasses = ['cookie-overlay'];
    if (this.state.panelHide) panelClasses.push('hide');
    return (
      <div ref={this.panel} className={panelClasses.join(' ')}>
        <div className="panel">
          <h1 className="heading"><LocElement {...data.headline} /></h1>
          <p className="description"><LocElement {...data.description} /></p>
          <span className="link" onClick={ () => { this.onLinkClick() } }><LocElement {...data.link_text} /></span>
          <button className="btn" onClick={ () => { this.onConsentClick() } }><LocElement {...data.button_text} /></button>
        </div>
        {
          this.state.overlaySlug &&
          <Overlay>
            <StaticContent slug={this.state.overlaySlug} onClose={() => {this.setState({overlaySlug: null})}} reportTransition={this.panelUnhide.bind(this)} />
          </Overlay>
        }
      </div>
    );
  }
}

export default CookieBanner
