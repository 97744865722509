import React, { Component } from 'react';

class Radiogroup extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      selected: props.selected || undefined
    }
  }

  onSelect(key) {
    this.setState({ selected: key }, () => {
      this.props.onSelect && this.props.onSelect(key);
    });
  }

  render() {
    let classes = ['radio-group', this.props.type]
    return (
      <div className={classes.join(' ')}>
        {
          Object.keys(this.props.data).map(key => {
            let content = this.props.type === 'arrows' ? (<span className="symbol"><svg viewBox="0 0 28 28"><path d="M0,28V0L28,14Z"/></svg></span>) : key;
            let btnClasses = ['btnRadio',`val${key}`];
            if (this.state.selected && this.state.selected === key) btnClasses.push('selected');
            return (
              <button key={key} className={btnClasses.join(' ')} onClick={(e) => { this.onSelect(key) }}>
                <span>{content}</span>
              </button>
            )
          })
        }
      </div>
    );
  }
}

export default Radiogroup
