import React, { Component } from "react";
import { default as Timer, Messages as TimerMessages } from "./../../../Timer";
import { random } from "./../../../utils/Helper";

class RobotSVG extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blink: false
    };
    this._svg = React.createRef();
  }

  componentDidMount() {
    this.blinkRoutine(true);
  }
  componentWillUnmount() {
    this._deregisterTimer();
  }

  blinkRoutine(bool) {
    if (bool) {
      return (this._timerId = Timer.register({
        maxValue: random(3000, 6000),
        cb: ({ msg }) => {
          this._timerId = null;
          if (msg === TimerMessages.KILLED) return;
          this.setState({ blink: true });
          this._timerId = Timer.register({
            maxValue: 100,
            cb: () => {
              this.setState({ blink: false });
              this.blinkRoutine(true);
            }
          });
        }
      }));
    }
    this._deregisterTimer();
  }

  _deregisterTimer() {
    if (this._timerId) {
      Timer.deregister(this._timerId);
    }
  }

  render() {
    let classes = this.state.blink ? "blink" : null;
    return (
      <svg ref={this._svg} viewBox="0 0 290 290" className={classes}>
        <defs>
          <linearGradient id="flameJetL" x1="195.96" y1="215.08" x2="187.31" y2="200.38" gradientUnits="userSpaceOnUse">
            <stop offset="0" className="flameTip" />
            <stop offset="1" className="flameBase" />
          </linearGradient>
          <linearGradient id="flameJetR" x1="102.14" y1="215.15" x2="111.37" y2="199.21" gradientUnits="userSpaceOnUse">
            <stop offset="0" className="flameTip" />
            <stop offset="1" className="flameBase" />
          </linearGradient>
          <radialGradient id="shadow" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
            <stop offset="0%" className="blobShadow inner" />
            <stop offset="90%" className="blobShadow outer" />
          </radialGradient>
          <filter id="eye-glow" filterUnits="userSpaceOnUse" x="-50%" y="-50%" width="200%" height="200%">
            <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur5" />
            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur10" />
            <feGaussianBlur in="SourceGraphic" stdDeviation="20" result="blur20" />
            <feMerge result="blur-merged">
              <feMergeNode in="blur10" />
              <feMergeNode in="blur20" />
            </feMerge>
            <feColorMatrix
              result="color-blur"
              in="blur-merged"
              type="matrix"
              values="1   0   0   0   0
                      0   2   0   0   0
                      0   0   0  .5   0
                      0   0   0   1   0"
            />
            <feMerge>
              <feMergeNode in="color-blur" />
              <feMergeNode in="blur5" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <ellipse className="shadow" cx="145" cy="250" rx="65" ry="15" fill="url(#shadow)" />
        <g id="Robot">
          <g id="Arm_R" className="arm-group">
            <g id="OberarmR">
              <rect className="arm" x="36.39" y="159.83" width="51.1" height="7.09" transform="translate(-66.58 45.01) rotate(-26.59)" />
              <circle className="joint" cx="84.79" cy="151.93" r="10.82" transform="translate(-56.59 50.51) rotate(-25.18)" />
            </g>
            <g id="UnterarmR">
              <polygon className="hand" points="63.91 212.77 74.2 226.89 66.78 229.58 54.3 217.05 58.71 209.18 59.56 208.56 54.18 202.97 60.79 198.14 64.5 204.96 65.33 204.35 74.16 202.56 82.29 218.27 77.46 224.51 67.16 210.4 63.91 212.77" />
              <rect className="arm" x="44.6" y="171.89" width="7.09" height="31.71" transform="translate(-101.41 64.45) rotate(-36.12)" />
              <path id="GelenkUR" className="joint" d="M42.75,180.37a6.72,6.72,0,1,0-9.38-1.47A6.72,6.72,0,0,0,42.75,180.37Z" />
              <path className="joint" d="M61,205.31A5.88,5.88,0,1,0,52.73,204,5.89,5.89,0,0,0,61,205.31Z" />
            </g>
          </g>
          <g id="Arm_L" className="arm-group">
            <g id="OberarmL">
              <rect className="arm" x="223" y="140.45" width="7.09" height="51.1" transform="translate(-36.78 263.67) rotate(-56.58)" />
              <circle className="joint" cx="205.21" cy="151.93" r="10.82" transform="translate(-32.39 245.43) rotate(-58)" />
            </g>
            <g id="UnterarmL">
              <polygon className="hand" points="223.17 218.18 213.03 232.41 220.48 235.02 232.82 222.36 228.33 214.53 227.47 213.92 232.79 208.27 226.13 203.52 222.5 210.38 221.66 209.78 212.8 208.09 204.85 223.88 209.75 230.07 219.9 215.85 223.17 218.18" />
              <rect className="arm" x="222.81" y="189.44" width="31.71" height="7.09" transform="translate(-57.01 275.32) rotate(-54.52)" />
              <path id="GelenkUL" className="joint" d="M244,185.55a6.72,6.72,0,1,1,9.38-1.58A6.73,6.73,0,0,1,244,185.55Z" />
              <path className="joint" d="M226.05,210.68a5.88,5.88,0,1,1,8.2-1.37A5.88,5.88,0,0,1,226.05,210.68Z" />
            </g>
          </g>
          <g id="ArmSockets" className="armsockets">
            <g id="ArmSocket_R">
              <path className="socket" d="M87.32,137l6.59,26.39-5.43,1.36a2.28,2.28,0,0,1-2.76-1.66l-5.49-22a2.28,2.28,0,0,1,1.66-2.76Z" />
              <circle id="Gelenk_R" className="guide" cx="145" cy="136.96" r="60.21" transform="translate(-8.04 9.04) rotate(-3.48)" />
            </g>
            <g id="ArmSocket_L">
              <circle id="Gelenk_L" className="guide" cx="145" cy="136.96" r="60.21" transform="translate(-8.04 9.04) rotate(-3.48)" />
              <path className="socket" d="M202.68,137l-6.59,26.39,5.43,1.36a2.28,2.28,0,0,0,2.76-1.66l5.49-22a2.28,2.28,0,0,0-1.66-2.76Z" />
            </g>
          </g>
          <g id="Jet_L" className="jet-group">
            <g>
              <polygon className="funnel" points="189.43 190.93 182.76 183.81 168.99 191.76 171.83 201.09 189.43 190.93" />
              <path className="socket" d="M185.93,178l-23.55,13.62,2.81,4.85a2.27,2.27,0,0,0,3.11.83L187.91,186a2.28,2.28,0,0,0,.83-3.11Z" />
              <path id="FlameL" className="flame-left" d="M190.6,196.19l5.28,15.1a2.55,2.55,0,0,1-1.09,3l-6.39,3.83a2.55,2.55,0,0,1-3.23-.51L174.6,205.58a2.56,2.56,0,0,1,.63-3.89l11.66-6.86A2.56,2.56,0,0,1,190.6,196.19Z" />
            </g>
            <circle className="guide" cx="145" cy="136.96" r="60.21" transform="translate(-8.04 9.04) rotate(-3.48)" />
          </g>
          <g id="Jet_R" className="jet-group">
            <g>
              <polygon className="funnel" points="100.57 190.93 107.24 183.81 121.01 191.76 118.17 201.09 100.57 190.93" />
              <path className="socket" d="M104.07,178l23.55,13.62-2.81,4.85a2.27,2.27,0,0,1-3.11.83L102.09,186a2.28,2.28,0,0,1-.83-3.11Z" />
              <path id="FlameR" className="flame-right" d="M97.4,196.19l-5.28,15.1a2.55,2.55,0,0,0,1.09,3l6.39,3.83a2.55,2.55,0,0,0,3.23-.51l10.57-12.07a2.56,2.56,0,0,0-.63-3.89l-11.66-6.86A2.56,2.56,0,0,0,97.4,196.19Z" />
            </g>
            <circle className="guide" cx="145" cy="136.96" r="60.21" transform="translate(-8.04 9.04) rotate(-3.48)" />
          </g>
          <g id="Body" className="body">
            <g id="Antenna" className="antenna">
              <path className="wire" d="M145.61,50s.44.85,2.24,4.5-.59,5.25-.59,5.25-2.08,1.53-4,3-.19,5-.19,5l2.54,6" />
              <path className="ball" d="M149.22,49.12A4.22,4.22,0,1,1,145,44.9a4.22,4.22,0,0,1,4.22,4.22" />
              <path className="socket" d="M132.25,81.38c0-5.73.09-9,12.75-9s12.75,3.24,12.75,9" />
            </g>
            <g id="Ball" className="ball">
              <circle className="mainshape" cx="145" cy="136.96" r="60.21" transform="translate(-8.04 9.04) rotate(-3.48)" />
              <path className="shade" d="M182.06,89.52a127.08,127.08,0,0,0-21,105.47,60.2,60.2,0,0,0,21-105.47Z" />
              <path className="shade" d="M107.94,89.52a126.31,126.31,0,0,1,24.66,75.17A127.16,127.16,0,0,1,129,195a60.2,60.2,0,0,1-21-105.47Z" />
              <path className="equator" d="M85.13,143.14a112.69,112.69,0,0,0,119.74,0" />
            </g>
            <path id="Mouth" className="mouth" d="M145,171c-29.49,0-26-13.43-22.52-12.72a114.51,114.51,0,0,0,22.52,2,114.51,114.51,0,0,0,22.52-2C171.05,157.6,174.49,171,145,171Z" />
            <g id="Eye_R" className="eye-group">
              <path className="socket" d="M109.18,123.6a14,14,0,1,0,14-14,14,14,0,0,0-14,14" />
              <path className="glow" d="M111.87,123.6a11.3,11.3,0,1,0,11.3-11.3,11.3,11.3,0,0,0-11.3,11.3" />
              <path className="eyeball" d="M111.87,123.6a11.3,11.3,0,1,0,11.3-11.3,11.3,11.3,0,0,0-11.3,11.3" />
              <path className="iris" d="M115.68,123.6a7.45,7.45,0,1,0,7.45-7.44,7.46,7.46,0,0,0-7.45,7.44" />
              <path className="shine" d="M119,121.83c0,2.28,1.85,0,4.13,0s4.12,2.27,4.12,0a4.13,4.13,0,1,0-8.25,0" />
            </g>
            <g id="Eye_L" className="eye-group">
              <path className="socket" d="M180.82,123.6a14,14,0,1,1-14-14,14,14,0,0,1,14,14" />
              <path className="glow" d="M178.13,123.6a11.3,11.3,0,1,1-11.3-11.3,11.3,11.3,0,0,1,11.3,11.3" />
              <path className="eyeball" d="M178.13,123.6a11.3,11.3,0,1,1-11.3-11.3,11.3,11.3,0,0,1,11.3,11.3" />
              <path className="iris" d="M174.32,123.6a7.45,7.45,0,1,1-7.45-7.44,7.46,7.46,0,0,1,7.45,7.44" />
              <path className="shine" d="M171,121.83c0,2.28-1.85,0-4.13,0s-4.12,2.27-4.12,0a4.13,4.13,0,1,1,8.25,0" />
            </g>
            <g id="Blink" className="blink-group">
              <g data-name="left">
                <circle className="eyeball" cx="166.97" cy="123.7" r="11.35" />
                <line className="slit" x1="155.67" y1="122.71" x2="178.27" y2="124.69" />
              </g>
              <g data-name="right">
                <circle className="eyeball" cx="123.28" cy="123.7" r="11.35" />
                <line className="slit" x1="111.98" y1="124.69" x2="134.58" y2="122.71" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
export default RobotSVG;
