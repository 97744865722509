import React, { Component } from "react";
import Assistant from "./assistant/Assistant";
import SpeechBubble from "./SpeechBubble";
import LocElement from "./LocElement";
class ScreenInTheMiddle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assistantFinished: false,
      assistantMode: props.assistantMode || "idle",
      addAssistantClasses: props.addAssistantClasses || null
    };
  }

  onAssistantStatus(response) {
    if (response.status === "idle") {
      return this.setState({ assistantFinished: true });
    }
  }

  render() {
    // let smallerThen1024px = window.innerWidth <= 1024;
    let bigLink,
      smallLink = null;
    if (this.props.linkTo && this.props.linkTo.button === "smallBubble") smallLink = this.props.linkTo;
    if (this.props.linkTo && this.props.linkTo.button === "bigBubble") bigLink = this.props.linkTo;
    let cssClasses = ["screen-in-the-middle"];
    // let cssClasses = ["screen-in-the-middle", "is-mb-column", "is-tb-column"];
    // cssClasses.push(this.props.bigSpeechBubble && this.props.smallSpeechBubble ? "is-dt-column" : "is-dt-row");
    let pdfBtnClasses = ["pdfButton"];
    if (!this.state.assistantFinished) {
      cssClasses.push("bubblesHidden");
      pdfBtnClasses.push("btnHidden");
    }

    return (
      <div className={cssClasses.join(" ")}>
        {this.props.bigSpeechBubble && this.props.smallSpeechBubble && <SpeechBubble type="main" mode="small" orientation="bottom" text={this.props.bigSpeechBubble} link={bigLink} />}
        {this.props.bigSpeechBubble && !this.props.smallSpeechBubble && <SpeechBubble type="main" mode="big" orientation="bottom" text={this.props.bigSpeechBubble} link={bigLink} />}
        <Assistant mode={this.state.assistantMode} animationClasses={this.state.addAssistantClasses} reportStatus={this.onAssistantStatus.bind(this)} />
        {this.props.bigSpeechBubble && this.props.smallSpeechBubble && <SpeechBubble type="side" mode="small" orientation="top" text={this.props.smallSpeechBubble} link={smallLink} />}
        {this.props.linkTo && (
          <a className={pdfBtnClasses.join(" ")} href={this.props.linkTo.href} target={this.props.linkTo.target}>
            <LocElement {...this.props.linkTo.btn_label} />
          </a>
        )}
      </div>
    );
  }
}

export default ScreenInTheMiddle;
