import React, { Component } from 'react';

export const ValidationTypes = {ALL: "all", AT_LEAST_ONE: "at_least_one"};

export class Group extends Component
{
  constructor(props) {
    super(props);
    this.state = {};
    this._refs = [];
    this._vals = null;
  }

  get value() { return this._vals }

  _onChange(e) {
    let validationType = this.props.validationType || ValidationTypes.ALL;
    this._vals = {};
    this._refs.forEach(r => {
      let v = r.current.value;
      if(!v) return;
      this._vals[r.current.name] = v;
    });
    if(validationType === ValidationTypes.ALL && Object.keys(this._vals).length !== this._refs.length) this._vals = null;
    if(validationType === ValidationTypes.AT_LEAST_ONE && Object.keys(this._vals).length === 0) this._vals = null;
    this.props.onChange(e);
  }

  render() {
    const childrenWithProps = React.Children.map(this.props.children, child => {
      if(child.type === "label") return child;
      let r = React.createRef();
      let c = React.cloneElement(child, { onChange: this._onChange.bind(this), ref: r });
      this._refs.push(r);
      return c;
    });

    return (
      <div className={this.props.className}>
        {childrenWithProps}
      </div>
    );
  }
}
