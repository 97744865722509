import React, { Component } from 'react';

class Gap extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      filled: props.examplefill ? true : false,
      fillData: {
        text: props.examplefill ? props.examplefill : null
      }
    }
  }

  getData() {
    return this.state.fillData.id || null;
  }

  fill(id, strg) {
    let response = 'empty';
    if (this.state.filled) {
      response = this.state.fillData.id;
    }
    let state = this.state;
    state.filled = true;
    state.fillData.id = id;
    state.fillData.text = strg;
    state.selected = false;
    this.setState({...state});
    return response;
  }

  unfill() {
    let response = this.state.fillData.id;
    let state = this.state;
    state.filled = false;
    state.fillData = {};
    state.selected = true;
    this.setState({...state});
    return response;
  }

  get isFilled() {
    return this.state.filled;
  }

  selected(bool) {
    this.setState({ selected: bool });
  }

  render() {
    let classes = [...this.props.cssClasses];
    if (this.state.selected) classes.push('selected');
    if (this.state.filled) classes.push('filled');
    return (
      <span className={classes.join(' ')} onClick={this.props.onClick}>
        { (this.state.filled && this.state.fillData.text) }
      </span>
    );
  }

}

export default Gap
