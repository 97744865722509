import React, {Component} from 'react';
import Logo from './../components/Logo.js';
import Loadbar from './../components/Loadbar.js';
import DataFetcher from './../utils/DataFetcher';
import DataPersister from './../utils/DataPersister';
import Localizator from './../utils/Localizator';
import User from './../User';
import imgsToPreload from "./SplashScreenImgsToLoad";

const __LOCALIZATION_FETCHING_THRESHOLD = 1000*60*60*12; //12h

function SplashScreen(WrappedComponent)
{
  return class extends Component {

    state = {
      loading: true,
      currentStep: -1,
      loadbarCurrent: 0
    };

    steps = (() => {
      let s = [/* TODO: add user aktualisieren falls einer vorhanden im localstorage ;) */];
      s = s.concat([
        { text: "Lade Inhalte", fn: this._loadingStepLocalization.bind(this) },
        { text: "Lade Flow", fn: this._loadingStepFlow.bind(this) }
      ]);

      imgsToPreload.map((file, i) => {
        return s.push({ text: `Lade SVG (${i+1}/${imgsToPreload.length}) - ${file}`, fn: () => { this._loadSingleImage(file) } })
      });
      return s;
    })();

    _loadingStepLocalization() {
      let localization = DataPersister.getItem('localization', null);
      if(localization && localization._timestamp && Date.now() - localization._timestamp < __LOCALIZATION_FETCHING_THRESHOLD){
        Localizator.data = localization;
        this.nextLoadingStep();
        return;
      }

      DataFetcher.fetch("content/localization", {})
        .then(response => {
          let c = response.content;
          Localizator.data = c;
          DataPersister.setItem("localization", c);
          this.nextLoadingStep();
        });
    }

    _loadingStepFlow() {
      let flow = DataPersister.getItem('flow', null);
      let f = User.isRegistered ? User.getDataEntry("flow") : null;
      if(flow && (!f || flow.pool._id === f.pool._id)){
        User.setNewFlow(flow)
        this.nextLoadingStep();
        return;
      }

      let fO = f ? {pool: f.pool._id} : {};
      DataFetcher.fetch("content/flow", fO)
        .then(response => {
          let c = response.content;
          DataPersister.setItem("flow", c);
          User.setNewFlow(c);
          this.nextLoadingStep();
        });
    }

    _loadSingleImage(file) {
      fetch(file).then(() => this.nextLoadingStep()).catch(() => this.nextLoadingStep());
    }

    nextLoadingStep() {
      this.setState({loadbarCurrent: this.state.loadbarCurrent + 1});
      if(!this.state.loading) return;
      let curStep = this.state.currentStep + 1;
      if(this.steps[curStep]){
        return this.setState({currentStep: curStep}, () => this.steps[curStep].fn());
      }
      this.setState({loading: false});
    }

    componentDidMount() {
      setTimeout(this.nextLoadingStep.bind(this), 500);
    }

    render() {
      // while checking user session, show "loading" message
      if(this.state.loading) return (
        <div className="SplashScreen">
          <div className="SplashScreen-header">
            <Logo mode="wide" />
            <Loadbar current={this.state.loadbarCurrent} total={this.steps.length + 1} />
          </div>
        </div>
      );

      // otherwise, show the desired route
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default SplashScreen;
