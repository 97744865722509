import uuid from 'react-uuid';

export const Messages = {FINISHED: "FINISHED", TIMING: "TIMING", KILLED: "KILLED"};
const __DEFAULT_STEP_TIME = 1000;
const __DEFAULTCB = ()=>{};

class Timer {
  constructor(){
    this._timers = [];
    this._afId = null;
    this._quiet = true;
  }

  _log(msg){
    !this._quiet && console.log(msg);
  }

  register({id = uuid(), maxValue = 0, cb = __DEFAULTCB, step = __DEFAULTCB, stepValue = __DEFAULT_STEP_TIME}){
    if(maxValue === 0 || (cb === __DEFAULTCB && step === __DEFAULTCB)) return cb({msg: Messages.FINISHED});
    this._timers.push({id, maxValue: maxValue, stepValue, cb, step, value: 0, timePassed: 0, startTime: Date.now()});
    this._log(`Timer with id ${id} added`);
    this._start();
    return id;
  }

  deregister(id, msg = Messages.KILLED){
    if(!id) return;
    this._timers.find(x => x.id === id).cb({id, msg});
    this._timers = this._timers.filter(x => x.id !== id);
    this._log(`Timer with id ${id} removed`);
    this._stop();
  }

  _start(){
    if(this._timers.length === 0 || this._afId) return;
    this._afId = window.requestAnimationFrame(this._step.bind(this));
  }

  _stop(){
    if(this._timers.length > 0) return;
    window.cancelAnimationFrame(this._afId);
    this._afId = null;
  }

  _step(){
    this._timers.forEach(t => {
      let msTimePassed = Date.now() - t.startTime;
      if((msTimePassed - t.timePassed) >= t.stepValue){
        t.step({msTimePassed,tp:t.timePassed,  id: t.id, msg: Messages.TIMING});
        t.timePassed = msTimePassed;
      }
      if(msTimePassed > t.maxValue) this.deregister(t.id, Messages.FINISHED);
    });
    this._afId = null;
    this._start();
  }
}

export default new Timer();
