import React, { Component } from 'react';
import LocElement from './../../LocElement';

class Hint extends Component
{
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <div className="hint">
        <div className="innerHint">
          <h2 className="headingHint">Hinweis</h2>{/* // TODO: Magic word alert! */}
          <p><LocElement {...this.props.text} /></p>
        </div>
      </div>
    );
  }
}

export default Hint
