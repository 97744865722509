import React, { Component, Fragment } from "react"
import Blaupause from "./Blaupause"

import Header from "./../Header"
import LocElement from "./../LocElement"
import Radiogroup from "./../Radiogroup"

const inputPlaceholder = "…"

class FeedbackItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: undefined,
      disabled: false
    }
  }

  setSelected(value) {
    this.setState({ selected: value })
    if (this.props.data.relatesTo && this.props.data.template === "singlechoice") {
      let enable = this.props.data.relatesTo.validation === value
      return this.props.onRelatedAnswer({ id: this.props.data._id, value: value }, { ref: this.props.data.relatesTo.item, enable: enable })
    }
    return this.props.onAnswer(this.props.data._id, value)
  }

  disabled(bool) {
    // NOTE: Should the content of an input field be cleared when set to disabled?
    this.setState({ disabled: bool })
  }

  componentDidMount() {
    // NOTE: Input fields related to singlechoices must be disabled by default
    if (this.props.data.template === "input" && this.props.data.relatesTo) {
      this.disabled(true)
    }
  }

  onInputChange(value) {
    this.setState({ selected: value })
    return this.props.onAnswer(this.props.data._id, value)
  }

  getItemComponent(obj) {
    switch (obj.template) {
      case "fragebogen":
        return (
          <div className="col">
            <Radiogroup data={obj.answers} onSelect={this.setSelected.bind(this)} type="arrows" />
          </div>
        )
      case "singlechoice":
        let answers = Object.keys(obj.answers).map((key, idx) => {
          let btnClasses = ["answer"]
          if (this.state.selected && this.state.selected === key) btnClasses.push("selected")
          return (
            <div key={key} className="col but">
              <button
                className={btnClasses.join(" ")}
                onClick={e => {
                  this.setSelected(key)
                }}
              >
                <LocElement {...obj.answers[key]} />
              </button>
            </div>
          )
        })
        return <Fragment>{answers}</Fragment>
      case "input":
        return (
          <div className="col">
            <input
              className="answer-input"
              disabled={this.state.disabled}
              placeholder={inputPlaceholder}
              onChange={e => {
                this.onInputChange(e.target.value)
              }}
              onFocus={e => {
                e.target.placeholder = ""
              }}
              onBlur={e => {
                e.target.placeholder = inputPlaceholder
              }}
            />
          </div>
        )
      default:
        return null
    }
  }

  render() {
    let classes = ["feedback-item"]
    if (this.props.data.template && this.props.data.template === "input") classes.push("no-border")
    if (this.state.disabled) classes.push("disabled")
    let containerClasses = ["container"]
    if (this.props.data.config) {
      if (this.props.data.config.desktop && this.props.data.config.desktop.itemsPerRow) containerClasses.push(`dt-${this.props.data.config.desktop.itemsPerRow}`)
      if (this.props.data.config.tablet && this.props.data.config.tablet.itemsPerRow) containerClasses.push(`tbl-${this.props.data.config.tablet.itemsPerRow}`)
      if (this.props.data.config.mobile && this.props.data.config.mobile.itemsPerRow) containerClasses.push(`mbl-${this.props.data.config.mobile.itemsPerRow}`)
    } else {
      containerClasses.push("dt-1", "tbl-1", "mbl-1")
    }
    if (this.props.data.template && this.props.data.template === "singlechoice") containerClasses.push("buts")
    return (
      <div className={classes.join(" ")}>
        <h2>
          <LocElement {...this.props.data.text} />
        </h2>
        <div className={containerClasses.join(" ")}>{this.getItemComponent(this.props.data)}</div>
      </div>
    )
  }
}

class Feedback extends Blaupause {
  constructor(props) {
    super(props)
    this.state = {
      answers: {}
    }
    this._refs = {}
  }

  onMCPClick() {
    if (this.userMayProceed()) {
      return {
        status: "question-finished",
        answers: this.state.answers
      }
    }
    return { status: "page-unfinished" }
  }

  getIDbyName(name) {
    let result = this.props.data.items.filter(item => item.name === name)
    return result.length ? result[0]._id : ""
  }

  userMayProceed() {
    for (let i = 0; i < this.props.data.items.length; i++) {
      let item = this.props.data.items[i]
      if (item.template === "fragebogen" && !this.state.answers.hasOwnProperty(item._id)) {
        return false
      }
      if (item.template === "singlechoice" && !this.state.answers.hasOwnProperty(item._id)) {
        return false
      }
      if (item.template === "input" && !item.hasOwnProperty("relatesTo") && !this.state.answers.hasOwnProperty(item._id)) {
        return false
      }
      if (item.template === "singlechoice" && item.hasOwnProperty("relatesTo")) {
        if (this.state.answers[item._id] === item.relatesTo.validation && !this.state.answers.hasOwnProperty(this.getIDbyName(item.relatesTo.item)))
          return false
      }
    }
    return true
  }

  getAnswer(itemID, value) {
    let buffer = { ...this.state.answers }
    buffer[itemID] = value
    this.setState({ answers: buffer }, () => {
      if (this.userMayProceed()) this.props.sendToMCP("proceed")
    })
  }

  getRelatedAnswer(answers, relation) {
    this.getAnswer(answers.id, answers.value)
    this._refs[relation.ref].current.disabled(!relation.enable)
  }

  render() {
    let contentClasses = ["content"]
    if (this.props.data.config.contentAlignment) contentClasses.push(this.props.data.config.contentAlignment)
    if (this.props.data.config.desktop && this.props.data.config.desktop.itemsPerRow) contentClasses.push(`dt-${this.props.data.config.desktop.itemsPerRow}`)
    if (this.props.data.config.tablet && this.props.data.config.tablet.itemsPerRow) contentClasses.push(`tbl-${this.props.data.config.tablet.itemsPerRow}`)
    if (this.props.data.config.mobile && this.props.data.config.mobile.itemsPerRow) contentClasses.push(`mbl-${this.props.data.config.mobile.itemsPerRow}`)

    let headerClasses = ["feedback"]

    return (
      <Fragment>
        <Header mode={headerClasses.join(" ")} config={this.props.data} />
        <div id="main" className="template feedback">
          <div className={contentClasses.join(" ")}>
            {this.props.data.items.map((item, index) => {
              let props = {
                key: item._id,
                data: item,
                onAnswer: this.getAnswer.bind(this),
                onRelatedAnswer: item.template === "singlechoice" && item.relatesTo ? this.getRelatedAnswer.bind(this) : null,
                ref: item.template === "input" && item.relatesTo ? this.getOrCreateRef(this._refs, item.name) : null
              }
              return <FeedbackItem {...props} />
            })}
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Feedback
