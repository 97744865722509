import React from 'react';
import RM from 'react-render-markup';
import { Link } from 'react-router-dom';
import StaticLink from './../components/StaticLink';
import Icon from './../components/Icon';
import LicenseData from "./../data/licenses.json";

const renderMarkup = (txt) => {
  txt = txt.replace("<p>#[licenses]</p>", "#[licenses]");
  txt = txt.replace("#[licenses]", `<ul>${Object.keys(LicenseData).map((k) => (LicenseData[k].licenses !== "UNLICENSED" ? `<li><strong>${k}</strong> (${LicenseData[k].licenses}): <a href="${LicenseData[k].repository}" target="_blank">Repository</a>, <a href="${LicenseData[k].licenseUrl}" target="_blank">Lizenz</a></li>` : '')).join('')}</ul>`);

  let x = RM(txt);
  let scRegex = /#\[(.*?)\]/ig;
  x = x.flatMap((str, idx) => {
    if(typeof str !== "string") return [str];

    let match;
    let reworkedStr = [];
    let tmpLastIdx = 0;
    let cnt = 0;
    while((match = scRegex.exec(str)) != null){
      reworkedStr.push(str.slice(tmpLastIdx, match.index));
      tmpLastIdx = match.index+match[0].length;
      let workers = match[1].split(';');
      switch(workers[0]){
        case "icon": /* #[icon;check] */
          reworkedStr.push((<Icon key={`icon-${idx}`} icon={workers[1]} />));
          break;
        case "link":  /* #[link;registriert;/login[;_TARGET_ default _self]] */
          reworkedStr.push((<Link key={`markup-gen-link-${idx}-${cnt}`} to={workers[2]} target={workers[3] || "_self"}>{workers[1]}</Link>));
          break;
        case "staticLink":  /* #[staticLink;Datenschutzerklärung;datenschutz] */
          reworkedStr.push((<StaticLink key={`markup-gen-staticlink-${idx}-${cnt}`} to={workers[2]}>{workers[1]}</StaticLink>));
          break;
        default: break;
      }

      cnt++;
    }
    reworkedStr.push(str.slice(tmpLastIdx));
    return reworkedStr;
  });
  return x;
};

export default renderMarkup;
