import React, { Component, Fragment } from "react";
import LocElement from "./../components/LocElement";
import Header from "./../components/Header";
import MCPOverlord from "./../utils/MCPOverlord";
import Localizator from "./../utils/Localizator";
import Events2Use from "./../utils/Events2Use";
import Tutorial from "./Tutorial";

class StaticContent extends Component {
  constructor(props) {
    super(props);
    this.state = { overlayIsOpen: false };
    this.element = React.createRef();
    this.transitionHandler = this.onTransitionEnd.bind(this);
    this.content = props.slug === "tutorial" ? null : this.getContentByName(props.slug);
  }

  getContentByName(name) {
    return {
      headline: Localizator.getLocale(`staticcontent_${name}.headline`, {}, true),
      text: Localizator.getLocale(`staticcontent_${name}.content`)
    };
  }

  componentDidMount() {
    window.requestAnimationFrame(() => {
      this.setState({ overlayIsOpen: true });
    });
    MCPOverlord.disableMCPs();
  }

  componentWillUnmount() {
    MCPOverlord.enableMCPs();
  }

  onTransitionEnd() {
    this.element.current.removeEventListener(Events2Use.transitionend, this.transitionHandler);
    if (this.props.onClose) this.props.onClose();
  }

  overlayClosed() {
    this.setState({ overlayIsOpen: false }, () => {
      if (this.props.reportTransition) this.props.reportTransition();
      this.element.current.addEventListener(Events2Use.transitionend, this.transitionHandler);
    });
  }

  render() {
    let overlayClasses = ["overlay"];
    if (this.state.overlayIsOpen) {
      overlayClasses.push("is-active");
    }

    let elements =
      this.props.slug === "tutorial" ? (
        <Tutorial overlayCloser={true} onClose={this.overlayClosed.bind(this)} />
      ) : (
        <Fragment>
          <Header config={this.content} overlayCloser={true} onClose={this.overlayClosed.bind(this)} />
          <div id="main" className="main static">
            <LocElement {...this.content.text} />
          </div>
        </Fragment>
      );

    return (
      <div ref={this.element} className={overlayClasses.join(" ")}>
        {elements}
      </div>
    );
  }
}

export default StaticContent;
