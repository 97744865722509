import React from 'react';
import User from './../../User';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    User.isRegistered === true
      ? <Component {...props} />
      : <Redirect to='/' />
  )} />
)

export default PrivateRoute;
