import React, { Component } from 'react';
import Icon from './../../Icon';

class Foldable extends Component
{
  constructor(props) {
    super(props);
    this.state = { open: props.open || false }
  }

  open() { this.setState({ open: true }) }
  onCloserClick() { this.setState({ open: false }) }

  render() {
    let classes = ['foldable'];
    if (this.props.className) classes.push(this.props.className);
    if (this.state.open) classes.push('open');
    return (
      <div className={classes.join(' ')}>
        <button onClick={this.onCloserClick.bind(this)} className="foldable-closer"><Icon icon='close' /></button>
        {this.props.children}
      </div>
    );
  }
}

export default Foldable
