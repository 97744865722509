import React, { Component } from 'react';
import uuid from 'react-uuid';
import LocElement from './../LocElement';

class CheckBox extends Component
{
  constructor(props) {
    super(props);
    this.state = {isChecked: false};
    this._checkbox = React.createRef();
    this._fallbackId = uuid();
  }

  get name(){ return this.props.name }

  get value() {
    if(!this._checkbox || !this._checkbox.current) return null;
    return this.state.isChecked ? (this._checkbox.current.value || true) : null;
  }

  get checked(){
    return this.state.isChecked;
  }

  componentDidMount() {
    if (this.props.focused) {
      setTimeout(() => {
        this._checkbox.current.focus();
      }, 500);
    }
  }

  render() {
    return (
      <div className={`ll-formelement ll-formelement-checkbox ${this.state.isChecked ? "focus": ""}`} key={`${this.props.id || this._fallbackId}-wrapper`}>
        <span className="top"></span>
        <span className="bottom"></span>
        <label>
          <input
            ref={this._checkbox}
            key={`${this.props.id || this._fallbackId}-checkbox`}
            id={this.props.id || this._fallbackId}
            name={this.props.name}
            type="checkbox"
            value={this.props.value || "checked"}
            checked={this.state.isChecked}

            onChange={(e) => {
              this.setState({isChecked: e.target.checked}, () => this.props.onChange(e));
            }}
          />
          <LocElement {...this.props.label} />
        </label>
      </div>
    );
  }
}

export default CheckBox
