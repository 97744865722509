import React, { Component } from 'react';
import LocElement from './../../LocElement';

class GapFill extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      hidden: false,
      disabled: props.disabled || false
    }
  }

  disabled(bool) {
    this.setState({disabled: bool})
    this.hide(bool);
  }

  hide(bool) {
    this.setState({hidden: bool})
  }

  render() {
    let classes = ['col'];
    let btnClasses = ['answer']; 
    if (this.state.hidden) btnClasses.push('hidden');
    if (this.props.example) btnClasses.push('xmpl');
    return (
      <div className={classes.join(' ')}>
        <button
          className={btnClasses.join(' ')}
          onClick={this.props.onClick}
          type="button"
          disabled={this.state.disabled}>
          <LocElement {...this.props.text} />
        </button>
      </div>
    );
  }

}

export default GapFill
