import React from 'react';

export default React.forwardRef((props, ref) => {

  let classes = ['grid'];
  if (props.className) classes.push(props.className);
  if (props.desktop && props.desktop.itemsPerRow) classes.push(`dt-${props.desktop.itemsPerRow}`)
  if (props.tablet && props.tablet.itemsPerRow) classes.push(`tbl-${props.tablet.itemsPerRow}`)
  if (props.mobile && props.mobile.itemsPerRow) classes.push(`mbl-${props.mobile.itemsPerRow}`)

  return (
    <div ref={ref} className={classes.join(' ')}>
      {props.children}
    </div>
  )
});
