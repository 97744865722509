import React from 'react';
import renderMarkup from './../utils/RenderMarkup';
import SVG from 'react-inlinesvg';
import User from './../User';

export default props => {
  let txt = props[User.lang];
  if (!txt) return null;
  if(typeof txt === "string") return (<span>{renderMarkup(txt)}</span>);
  if(!txt.text) return null;
  switch (txt.textType) {
    case "text":
      return (<span>{renderMarkup(txt.text)}</span>);
    case "image":
      return (<img src={txt.text} alt="" />);
    case "mathml":
      return (renderMarkup(txt.text));
    case "inline-svg":
      return (<span className="ziggy">{renderMarkup(txt.text)}</span>);
    case "svg":
      let style = txt.style ? txt.style : null;
      return (<div className="media" style={style}>
        <SVG
          cacheRequests={true}
          loader={<span>loading...</span>}
          src={txt.text}
        />
      </div>);
    case "html":
      return (renderMarkup(txt.text));
    default:
      return (renderMarkup(txt.text));
  }
}
