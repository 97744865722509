import React, { Component, Fragment } from "react";
import Header from "./../components/Header";
import SecHeadline from "./../components/SecHeadline";
import MCP from "./../components/MCP";
import LocElement from "./../components/LocElement";
import User from "./../User";
import { Redirect } from "react-router-dom";

import ScreenInTheMiddle from "./../components/ScreenInTheMiddle";

class FormElementView extends Component {
  constructor(props) {
    super(props);
    this._cv = React.createRef();
    this.state = {
      mcpMode: "register",
      mcpDisabled: true,
      currentScreen: 0
    };

    this._screens = [];
  }

  _createFormFragment(o, ele) {
    return (
      <Fragment>
        {o.text && (
          <p>
            <LocElement {...o.text} />
          </p>
        )}
        {o.label && (
          <label htmlFor={o._id}>
            <LocElement {...o.label} />
          </label>
        )}
        {ele}
      </Fragment>
    );
  }

  _currentValueChanged(e) {
    let val = this._cv.current.value;
    this._screens[this.state.currentScreen].givenValue = val;
    if (!val) return this.setState({ mcpDisabled: true });
    this.setState({ mcpDisabled: false });
  }

  _finishedWithView() {
    let vals = {};
    this._screens.forEach(s => {
      if(s.isInterim) return;
      if (typeof s.givenValue == "object") Object.keys(s.givenValue).forEach(k => (vals[k] = s.givenValue[k]));
      else vals[s.key] = s.givenValue;
    });
    return vals;
  }

  getProgress() {
    return this.state.currentScreen / this._screens.length;
  }

  onMCPClick() {
    let curr = this.state.currentScreen + 1;
    if (!this._screens[curr]) {
      return this._finishedWithView();
    }
    if (typeof this._screens[curr] === "function") this._screens[curr] = this._screens[curr]();
    this.setState({ currentScreen: curr, mcpDisabled: true });
  }

  onRegNoticeConfirm() {
    let curr = this.state.currentScreen + 1;
    this.setState({ regNotice: false, currentScreen: curr, mcpDisabled: true });
  }

  render(injection = null) {
    if (User.isRegistered) return <Redirect to="/hub" />;

    let cs = this._screens[this.state.currentScreen];

    if (this.state.regNotice && cs.isInterim) {
      return (
        <Fragment>
          <Header mode="welcome" config={this.state.header} />
          <div id="main" className="template welcome">
            <div className="content">
              <ScreenInTheMiddle {...cs.regNoticeScreen} />
            </div>
          </div>
          <MCP
            ref={this.mcp}
            onClick={e => {
              this.onRegNoticeConfirm();
            }}
            mode={this.state.mcpMode}
            progress={0}
            disabled={false}
          />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Header mode="welcome" config={this.state.header} />
        <div id="main" className="main register">
          {cs.intro && (
            <p className="intro">
              <LocElement {...cs.intro} />
            </p>
          )}
          <SecHeadline text={cs.secHeadline} />
          <div className="content">
            <div className="value-holder">{cs.ele}</div>
            {injection}
          </div>
        </div>
        <MCP
          disabled={this.state.mcpDisabled}
          ref={this.mcp}
          onClick={e => {
            this.onMCPClick();
          }}
          mode={this.state.mcpMode}
          progress={this.getProgress()}
          handleKey={true}
        />
      </Fragment>
    );
  }
}

export default FormElementView;
