import DataPersister from './utils/DataPersister';
import DataFetcher from './utils/DataFetcher';
import dotty from 'dotty';
import diff from 'deep-diff';

const __DEFAULT_USER_DATA = {
  isRegistered: false,
  linkedAccount: null,
  flow: null,
  answers: {
    test: {},
    questionnaire: {},
    survey: {}
  },
  timeToSolve: {
    test: {},
    questionnaire: {},
    survey: {}
  },
  flags: {
    testFinished: false,
    questFinished: false,
    surveyFinished: false
  },
  lang: "german",
  consent: {
    maySeeResults: false,
    tutorial: false,
    cookies: false
  },
  created_at: -1,
};

const _clone = (x) => {
  let y = JSON.parse(JSON.stringify(x));
  delete y.personal;
  delete y.isRegistered;
  return y;
};

class User {
  constructor(){
    console.log("User created");
    this._data = DataPersister.getItem('user', Object.assign({}, __DEFAULT_USER_DATA, {"created_at": Date.now()}));

    this._savingState = {
      timeoutId: null,
      delay: 100,
      lastSavedObject: this._data.isRegistered ? _clone(this._data) : null,
      save: () => {
        let writeableData = _clone(this._data);

        let differences = diff(this._savingState.lastSavedObject, writeableData);
        if(!differences) return;
        let sendAbles = {
          linkedAccount: this._data.linkedAccount,
          save: {},
          delete: []
        };

        for(let i = 0; i < differences.length; i++){
          if(differences[i] && differences[i].path){
            let dotPath = differences[i].path.join(':');
            switch(differences[i].kind){
              case "N":
              case "E":
                sendAbles.save[dotPath] = differences[i].rhs;
                break;
              case "A":
                sendAbles.save[`${dotPath}.${differences[i].index}`] = differences[i].item.rhs;
                break;
              case "D":
                sendAbles.delete.push(dotPath);
                break;
              default: break;
            }
          }else{
            sendAbles.save.$ = sendAbles.save.$ || {};
            sendAbles.save.$ = Object.assign({}, sendAbles.save.$, JSON.parse(JSON.stringify(differences[i].rhs)));
          }
        }

        DataFetcher.fetch('push/user', sendAbles).then(
          resp => {
            if(!resp) return;
            this._savingState.lastSavedObject = _clone(writeableData);
          }
        );
      }
    };

    console.log("Current Userdata ->", this._data);

    this._emitSaver = () => this._pushDataToRemote(true);

    window.addEventListener('beforeunload', this._emitSaver);
  }

  setDataEntry(key, value){
    dotty.put(this._data, key, value);
    DataPersister.setItem('user', this._data);
    this._pushDataToRemote();
  }

  _pushDataToRemote(emit = false){
    if(!this._data.isRegistered) return;

    if(emit){
      return this._savingState.save();
    }

    if(this._savingState.timeoutId){
      clearTimeout(this._savingState.timeoutId);
      this._savingState.timeoutId = null;
    }

    this._savingState.timeoutId = setTimeout(() => {
      this._savingState.save();
    }, this._savingState.delay);
  }

  get lang() {
    return this.getDataEntry('lang');
  }

  setDataEntries(obj){
    if(!obj) return;
    Object.keys(obj).forEach(k => dotty.put(this._data, k, obj[k]));
    DataPersister.setItem('user', this._data);
    this._pushDataToRemote();
  }

  getDataEntry(key, defaultValue = null){
    return dotty.get(this._data, key) || defaultValue;
  }

  get isRegistered() {
    return this.getDataEntry('isRegistered')
  }

  setNewFlow(flow) {
    if(!this._data.flow){
      let _flow = JSON.parse(JSON.stringify(flow));
      delete _flow.pool.config;
      let keys = ["test", "questionnaire", "survey"];
      for(let i = 0; i < keys.length; i++){
        let d = dotty.get(_flow, keys[i]);
        dotty.put(_flow, keys[i], d.map(x => x._id));
      }
      this.setDataEntry('flow', _flow);
    }
  }

  login(data){
    console.log(data);
    this._data = Object.assign({}, __DEFAULT_USER_DATA, data);
    this._data.isRegistered = true;
    this._data.answers.test = this._data.answers.test || {};
    this._data.answers.questionnaire = this._data.answers.questionnaire || {};
    this._data.answers.survey = this._data.answers.survey || {};
    this._data.timeToSolve.test = this._data.timeToSolve.test || {};
    this._data.timeToSolve.questionnaire = this._data.timeToSolve.questionnaire || {};
    this._data.timeToSolve.survey = this._data.timeToSolve.survey || {};
    DataPersister.setItem('user', this._data);
  }

  logout(){
    window.removeEventListener('beforeunload', this._emitSaver);
    this._pushDataToRemote(true);
    this._data = null;
    DataPersister.clearEverything();
    setTimeout(() =>{
      window.location = `${window.location.protocol}//${window.location.host}`;
    }, 100);
  }

  register(data){
    return DataFetcher.fetch('push/account', data).then(
      response => {
        if(!response.success) return console.error(response.err);
        let linkedAccount = response.userdata._id;
        delete response.userdata._id;
        this.setDataEntries({isRegistered: true, personal: response.userdata, linkedAccount});
        return Promise.resolve()
      }
    )
  }
}

export default new User();
