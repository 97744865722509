import React, { Component } from 'react';
import {ConditionalWrapper} from './../utils/Helper';
import LocElement from './LocElement';

class SpeechBubble extends Component
{
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={`speech-bubble speech-bubble-${this.props.mode} speech-bubble-${this.props.type} speech-bubble-${this.props.orientation}`}>
        <ConditionalWrapper
          condition={this.props.link}
          wrapper={
            (children) => {
              return (<a className="bubblelink" href={this.props.link.href} target={this.props.link.target}>{children}</a>)
            }
          }>
          <LocElement {...this.props.text} />
        </ConditionalWrapper>
      </div>
    );
  }
}

export default SpeechBubble
