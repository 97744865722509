import Quizmaster from './Quizmaster';
import Localizator from './../utils/Localizator';
import User from './../User';
import DataPersister from './../utils/DataPersister';

import {default as Timer, Messages as TimerMessages} from './../Timer';
import { AnimationTypes } from './../components/assistant/Assistant';

class Test extends Quizmaster
{
  constructor(props) {
    super(props);

    this.data = User.getDataEntry('flow.test');
    this.content = DataPersister.getItem('flow').test;

    this._answers = User.getDataEntry('answers.test', {});
    this._timeToSolve = User.getDataEntry('timeToSolve.test', {});
    this._userHasStartedTest = Object.keys(this._answers).length !== 0;

    this.state = {
      currentQuestion: this._userHasStartedTest ? Object.keys(this._answers).length : -1,
      interimScreenFlag: this._userHasStartedTest ? false : true,
      maxQuestions: this.data.length,
      mcpMode: "tracker",
      testMode: "question"
    };

    this._timerId = null;

    this._interimScreenFlag = this.state.interimScreenFlag;

    let welcome = this._userHasStartedTest ? [] : [{
      content: {
        bigSpeechBubble: Localizator.getLocale('zwischenscreen_vor_leistungstest.speechbubble_1'),
        smallSpeechBubble: Localizator.getLocale('zwischenscreen_vor_leistungstest.speechbubble_2'),
        assistantMode: AnimationTypes.TWIRL,
        addAssistantClasses: null
      }
    }];

    this._interimScreens = [].concat(welcome,[
      {
        time: 18*60*1000,
        timerId: null,
        content: {
          bigSpeechBubble: Localizator.getLocale('zwischenscreen_18min.speechbubble_1'),
          smallSpeechBubble: Localizator.getLocale('zwischenscreen_18min.speechbubble_2'),
          assistantMode: AnimationTypes.HINUNDHER,
          addAssistantClasses: null
        }
      },
      {
        time: 36*60*1000,
        timerId: null,
        content: {
          bigSpeechBubble: Localizator.getLocale('zwischenscreen_36min.speechbubble_1'),
          assistantMode: AnimationTypes.WINKEN,
          addAssistantClasses: null
        }
      },
      {
        time: 54*60*1000,
        timerId: null,
        content: {
          bigSpeechBubble: Localizator.getLocale('zwischenscreen_54min.speechbubble_1'),
          smallSpeechBubble: Localizator.getLocale('zwischenscreen_54min.speechbubble_2'),
          assistantMode: AnimationTypes.GATE_RING,
          addAssistantClasses: null
        }
      },
      {
        time: 65*60*1000,
        timerId: null,
        content: {
          bigSpeechBubble: Localizator.getLocale('zwischenscreen_65min.speechbubble_1'),
          smallSpeechBubble: Localizator.getLocale('zwischenscreen_65min.speechbubble_2'),
          assistantMode: AnimationTypes.IDLE,
          addAssistantClasses: [AnimationTypes.EYES_GLOW]
        }
      }
    ]);

  }

  _deregisterTimers() {
    if(this._timerId) {
      Timer.deregister(this._timerId);
    }
    for(let i = 0; i < this._interimScreens.length; i++)
      Timer.deregister(this._interimScreens[i].timerId);
  }

  componentDidMount() {
    super.componentDidMount();
    this._interimScreens.forEach(interimScreen => {
      if(!interimScreen.time) return;
      interimScreen.timerId = Timer.register({
        maxValue: interimScreen.time,
        cb: (o) => {
          interimScreen.timerId = null;
          if(o.msg === TimerMessages.KILLED) return;
          if(o.msg === TimerMessages.FINISHED) {
            this._interimScreenFlag = true;
          }
        }
      })
    });
  }

  componentWillUnmount() {
    this._deregisterTimers();
  }

  updateAnswers(data) {
    super.updateAnswers(data);
    User.setDataEntry('answers.test', this._answers);
    User.setDataEntry('timeToSolve.test', this._timeToSolve);
  }

  onTestFinish() {
    User.setDataEntry('flags.testFinished', true);
    super.onTestFinish();
  }

  startQuestion(config) {
    super.startQuestion(config);
    this.setMCPMode('timer');
    this.startTimer(config.timer);
    //this.startTimer(24000);
  }

  stopTimer() {
    if(this._timerId) Timer.deregister(this._timerId);
  }

  timesUp (data) {
    document.getElementsByClassName('App')[0].scroll(0,0);
    this._question.current.disabled(false);
    switch (data.status) {
      case "finish":
        this.updateAnswers(data.answers);
        return this.proceed();
      case "change":
        this.setMCPMode('timer');
        return this.startTimer(data.time);
      default: return;
    }
  }

  startTimer(value) {
    this.mcp.current.showTime(value * 1000);
    if(this._timerId) Timer.deregister(this._timerId);
    this._timerId = Timer.register({
      maxValue: value * 1000,
      cb: (o) => {
        this._timerId = null;
        if(o.msg === TimerMessages.KILLED) return;
        if(o.msg === TimerMessages.FINISHED) {
          return this._question.current.timesUp();
        }
      },
      step: (o) => {
        return this.mcp.current.showTime(value * 1000 - o.msTimePassed);
      }
    })
  }

}

export default Test
