import React, {Component} from 'react';
import logoWide from './../media/Logo_KC21-wide.svg';
import logoShort from './../media/Logo_KC21-short.svg';

class Logo extends Component
{
  render() {
    let classes = ['logo'];
    classes.push(this.props.mode);
    if (this.props.responsive) classes.push('responsive');
    return (
      <div className={classes.join(' ')}>
        <img src={logoWide} className="wide" alt="KC21 Logo" />
        <img src={logoShort} className="short" alt="KC21 Logo" />
      </div>
    )
  }
}

export default Logo;
