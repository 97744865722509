import React, { Component } from 'react';
import uuid from 'react-uuid';

const __NONEVALUE = "LL_NONE";

class DropDown extends Component
{
  constructor(props) {
    super(props);
    this.state = {selectedValue: __NONEVALUE};
    this._dropdown = React.createRef();
    this._fallbackId = uuid();
  }

  get name(){ return this.props.name }

  get value() {
    if(!this._dropdown || !this._dropdown.current) return null;
    return this._dropdown.current.value;
  }

  componentDidMount() {
    if (this.props.focused) {
      setTimeout(() => {
        this._dropdown.current.focus();
      }, 500);
    }
  }

  render() {
    return (
      <div className={`ll-formelement ll-formelement-dropdown ${this.state.selectedValue !== __NONEVALUE ? "focus": ""}`} key={`${this.props.id || this._fallbackId}-wrapper`}>
        <span className="top"></span>
        <span className="bottom"></span>
        <select
          ref={this._dropdown}
          key={`${this.props.id || this._fallbackId}-select`}
          id={this.props.id || this._fallbackId}
          name={this.props.name}
          value={this.state.selectedValue}
          onChange={(e) => {
            this.setState({selectedValue: e.target.value});
            this.props.onChange(e);
          }}
        >
        {this.props.options && this.props.options.map((o, idx) => (<option key={idx} disabled={o.disabled || false} value={o.value || __NONEVALUE}>{o.label && o.label.german ? o.label.german : (o.value || __NONEVALUE)}</option>))}
      </select>
      </div>
    );
  }
}

export default DropDown
