import React, { Component, Fragment } from 'react';
import Blaupause from './Blaupause';

import Header from './../Header';
import SecHeadline from './../SecHeadline';
import LocElement from './../LocElement';
import Radiogroup from './../Radiogroup';

class FragebogenItem extends Component
{
  constructor(props) {
    super(props);
    this.state = { selected: undefined }
  }

  setSelected(key) {
    this.setState({ selected: key });
    this.props.onAnswer(this.props.data._id, key);
  }

  render() {
    return (
      <div className="fragebogen-item">
        <h2><LocElement {...this.props.data.text} /></h2>
        <Radiogroup data={this.props.data.answers} onSelect={this.setSelected.bind(this)} type="arrows" />
      </div>
    );
  }
}

class Fragebogen extends Blaupause
{
  constructor(props) {
    super(props);
    this.state = {
      answers: {}
    }
  }

  onMCPClick() {
    if (this.userMayProceed()) {
      return {
        status: "question-finished",
        answers: this.state.answers
      }
    }
    return { status: "page-unfinished" }
  }

  userMayProceed() {
    return Object.keys(this.state.answers).length === this.props.data.items.length;
  }

  getAnswer(itemID, value) {
    let buffer = {...this.state.answers};
    buffer[itemID] = value;
    this.setState({answers: buffer}, () => {
      if (this.userMayProceed()) this.props.sendToMCP('proceed');
    });
  }

  render() {
    let contentClasses = ['content'];
    if (this.props.data.config.contentAlignment) contentClasses.push(this.props.data.config.contentAlignment);
    if (this.props.data.config.desktop && this.props.data.config.desktop.itemsPerRow) contentClasses.push(`dt-${this.props.data.config.desktop.itemsPerRow}`);
    if (this.props.data.config.tablet && this.props.data.config.tablet.itemsPerRow) contentClasses.push(`tbl-${this.props.data.config.tablet.itemsPerRow}`);
    if (this.props.data.config.mobile && this.props.data.config.mobile.itemsPerRow) contentClasses.push(`mbl-${this.props.data.config.mobile.itemsPerRow}`);

    let headerClasses = ['fragebogen'];
    if (this.props.showIntro) headerClasses.push('intro');
    return (
      <Fragment>
        <Header mode={headerClasses.join(' ')} config={this.props.data} isFragebogenSkala={true} />
        <div id="main" className="template fragebogen">
          <SecHeadline text={this.props.data.secondaryHeadline} />
          <div className={contentClasses.join(' ')}>
            {
              this.props.data.items.map((item, index) => {
                let itemID = item._id;
                return (
                  <FragebogenItem key={itemID} data={item} onAnswer={this.getAnswer.bind(this)}/>
                )
              })
            }
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Fragebogen
