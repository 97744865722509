class MCPOverlord {
  constructor(){
    this._mcps = [];
  }

  register(mcp){
    this._mcps.push(mcp);
  }

  deregister(mcp){
    this._mcps = this._mcps.filter(x => x === mcp);
  }

  enableMCPs(){
    this._mcps.forEach(mcp => mcp.beingOverlorded(false));
  }

  disableMCPs(){
    this._mcps.forEach(mcp => mcp.beingOverlorded(true));
  }
};

export default new MCPOverlord();
