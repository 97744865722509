import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import Icon from './Icon';
import LocElement from './LocElement';
import MCPOverlord from './../utils/MCPOverlord';
import User from './../User';
import Overlay from './Overlay';
import StaticContent from './../views/StaticContent';

import footernav from './../data/footernavigation';

class Footer extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      navIsOpen: false,
      overlaySlug: null
    };
    this.navContent = footernav;
    this._overlay = React.createRef();
  }

  openMenu({noOverlording} = {noOverlording: false}) {
    this.setState({ navIsOpen: true }, () => {
      !noOverlording && MCPOverlord.disableMCPs();
      this._overlay && this._overlay.current && this._overlay.current.overlayClosed();
    });
  }
  closeMenu({noOverlording} = {noOverlording: false}) {
    this.setState({ navIsOpen: false }, () => { !noOverlording && MCPOverlord.enableMCPs(); });
  }
  toggleMenu() {
    return this.state.navIsOpen ? this.closeMenu() : this.openMenu();
  }

  render() {

    let footerClasses = ['pageFooter'];
    let navClasses = ['footer-navigation'];
    let burgerClasses = ['hamburger', 'hamburger--squeeze'];

    if(this.props.isBranded) {
      footerClasses.push('is-branded');
      navClasses.push('is-branded');
    }

    if (this.state.navIsOpen) {
      navClasses.push('is-active');
      burgerClasses.push('is-active');
    }

    let navEntries = this.navContent.entries.map((entry, index) => {
      if (entry.type === "static") {
        return (
          <li key={index}>
            <div className="button" onClick={(e) => {
              this.closeMenu({noOverlording: true});
              this.setState({overlaySlug: entry[User.lang].href})
            }}>
              <Icon icon={entry.np_icon.id} />
              <LocElement {...entry} />
            </div>
          </li>
        )
      }

      if(entry.type === "function") {
        // Check if user is logged in
        return User.isRegistered ? (
          <li key={index}>
            <div className="button" onClick={(e) => {
                this.closeMenu();
                User.logout();
            }}>
              <Icon icon={entry.np_icon.id} />
              <LocElement {...entry} />
            </div>
          </li>
        ) : null
      }
      return (
        <li key={index}>
          <Link to={entry[User.lang].href} target="_blank" onClick={(e) => {this.closeMenu()}}>
            <Icon icon={entry.np_icon.id} />
            <LocElement {...entry} />
          </Link>
        </li>
      )
    });

    return (
      <footer className={footerClasses.join(' ')}>

        <div className={navClasses.join(' ')}>
          <div className="menu-wrapper">
            <h2 className="menu-hl">{this.navContent.headline[User.lang]}</h2>
            {
              this.props.isBranded && this.props.isBranded.logo &&
              <div className="branding-container">
                <div className="branding-text">{this.navContent.brandingText[User.lang]}</div>
                <div className="branding-logo"><img src={this.props.isBranded.logo.url} alt={this.props.isBranded.logo.alt} /></div>
              </div>
            }
            <nav>
              <ul>{navEntries}</ul>
            </nav>
          </div>
        </div>

        <div className="container">
          <Logo mode="wide" responsive />
          <div className="menu-container">
            {
              this.props.isBranded && this.props.isBranded.logo &&
              <div className="branding-footer-logo"><img src={this.props.isBranded.logo.url} alt={this.props.isBranded.logo.alt} /></div>
            }
            <nav className="burger-menu">
              <button onClick={(e) => {this.toggleMenu()}} className={burgerClasses.join(' ')} type="button">
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
            </nav>
          </div>
        </div>
        {
          this.state.overlaySlug &&
          <Overlay>
            <StaticContent ref={this._overlay} slug={this.state.overlaySlug} onClose={() => {this.setState({overlaySlug: null})}}/>
          </Overlay>
        }
      </footer>
    );
  }
}

export default Footer;
