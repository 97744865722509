import React, { Fragment } from 'react';
import Blaupause from './Blaupause';

import Header from './../Header';
import SecHeadline from './../SecHeadline';
import GridContainer from './../GridContainer';
import LocElement from './../LocElement';

import Hint from './modules/Hint';

const inputPlaceholder = '…';

class Inputs extends Blaupause
{
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      modus: 'intro',
      screenCounter: 0
    }
    this._form = React.createRef();
    this._answers = {};
  }

  getAnswersLength() {
    return Object.keys(this._answers).length;
  }

  getAnswersLengthById(id) {
    let arr = this.props.data.items.filter(item => item._id === id);
    return Object.keys(arr[0].answers).length;
  }

  userMayProceed() {
    let itemCount = this.props.data.items.length;
    let arr = [];
    Object.keys(this._answers).map(key => {
      return Object.keys(this._answers[key]).length === this.getAnswersLengthById(key) ? arr.push(key) : null;
    });
    if (arr.length === itemCount) {
      return "question-finished";
    }
    if (this._answers[this.props.data.items[this.state.screenCounter]._id] && Object.keys(this._answers[this.props.data.items[this.state.screenCounter]._id]).length === this.getAnswersLengthById(this.props.data.items[this.state.screenCounter]._id)) {
      return "multipart-change";
    }
    return "question-unfinished"
  }

  getType(strg) {
    switch (strg) {
      case 'numerical':
        return 'number';
      default:
        return 'text';
    }
  }

  collectData() {
    return this.getInputs();
  }

  getInputs() {
    let fields = this._form.current.querySelectorAll('input.answer-input');
    let answers = {};
    Array.from(fields).forEach(field => {
      if (field.value !== '') answers[field.name] = field.value;
      // if (field.value !== '' && !field.hasAttribute('pattern')) answers[field.name] = field.value;
      // if (field.value !== '' && field.hasAttribute('pattern') && field.checkValidity()) answers[field.name] = field.value;
    });
    let buffer = {...this._answers};
    if (Object.keys(answers).length !== 0) buffer[this.props.data.items[this.state.screenCounter]._id] = answers;
    this._answers = buffer;
    return this._answers;
  }

  onFieldChange(e) {
    this.getInputs();
    if (this.userMayProceed() !== 'question-unfinished') this.props.sendToMCP('timer advanced');
    else this.props.sendToMCP('timer'); // NOTE: oder letzter Zustand des MCP
  }

  getPattern(expect, length) {
    // NOTE: Sollte eigentlich sein "jeder Buchstabe aber keine Zahlen und keine Leerzeichen"
    return `[a-zA-ZäöüÄÖÜß]{${length},}${expect}$`;
    // return `\\w{${length},}${expect}$`;
  }

  render() {
    let itemsToShow = this.props.data.items ? this.props.data.items.slice(this.state.screenCounter*this.props.data.config.maxItemsPerScreen, (this.state.screenCounter+1) * this.props.data.config.maxItemsPerScreen) : [];

    let contentClasses = ['content'];
    if (this.props.data.config.contentAlignment) contentClasses.push(this.props.data.config.contentAlignment);
    if (this.props.data.config.maxItemsPerScreen > 1) contentClasses.push('rows');

    let headerClasses = ['test', this.state.modus];

    return (
      <Fragment>
        <Header mode={headerClasses.join(' ')} config={this.props.data} />
        <div id="main" className="template input">
          {
            // Check if SecHeadline is needed
            ((this.state.modus === "intro" && this.props.data.example) || this.state.modus === "question") &&
            <SecHeadline example={this.props.data.example && this.state.modus === 'intro' ? this.props.data.example : null} text={this.props.data.secondaryHeadline} />
          }
          <div className={contentClasses.join(' ')}>
            {
              this.state.modus === "intro" && (
                <Fragment>
                  {
                    this.props.data.example &&
                    this.props.data.example.map((ex, idx) => {

                      let txt = ex.text ? (
                        <div className="question">
                          <LocElement {...ex.text} />
                        </div>
                      ) : null;

                      let answers = Object.keys(ex.answers).map((key, index) => {
                        return (
                          <div className="col" key={`expl-answer${key}_${index}`}>
                            <input
                              className="answer-input example"
                              type="text"
                              key={`field${ex._id}_${idx}`}
                              name={key}
                              placeholder={ex.answers[key].hasOwnProperty('german') ? ex.answers[key].german : '...'}
                              disabled={true}
                              autoComplete="off" />
                          </div>
                        )
                      });

                      let exampleDescription = this.props.data.exampleDescription ? (
                        <div className="xmplDescription">
                          <div className="container">
                            <LocElement {...this.props.data.exampleDescription} />
                          </div>
                        </div>
                      ) : null;

                      return (
                        <Fragment key={`expl-task${idx}`}>
                          <div className="task">
                            {txt}
                            <GridContainer className='answers' {...ex.config}>
                              {answers}
                            </GridContainer>
                          </div>
                          {exampleDescription}
                        </Fragment>
                      )
                    })
                  }
                  {
                    this.props.data.hint &&
                    <Hint text={this.props.data.hint} />
                  }
                </Fragment>
              )
            }

            {
              this.state.modus === "question" && (
                itemsToShow.map((item, index) => {
                  let txt = item.text ? (<div className="question"><LocElement {...item.text} id={`text${index}`} /></div>) : null;
                  let answers = Object.keys(item.answers).map((key, idx) => (
                    <div className="col" key={`answer${index}_${idx}`}>
                      <input
                        className="answer-input"
                        type={this.getType(item.answers[key].pattern)}
                        key={`field${item._id}_${idx}`}
                        name={key}
                        placeholder={inputPlaceholder}
                        onFocus={(e) => { e.target.placeholder = '' }}
                        onBlur={(e) => { e.target.placeholder = inputPlaceholder }}
                        pattern={item.answers[key].expect ? this.getPattern(item.answers[key].expect, item.answers[key].minLength) : null }
                        onChange={this.onFieldChange.bind(this)}
                        disabled={this.state.disabled}
                        autoComplete="off" />
                    </div>
                  ));
                  return (
                    <div key={`fragment${index}`} className="task">
                      {txt}
                      <GridContainer key={`input-grid`} ref={this._form} className='answers' {...item.config}>
                        {answers}
                      </GridContainer>
                    </div>
                  );
                })
              )
            }
          </div>
        </div>
      </Fragment>
    );
  }

}

export default Inputs
