import React, { Component } from 'react';
import { ConditionalWrapper } from './../utils/Helper';

class Icon extends Component
{

  /**
   * Props:
   * icon — String of icon name e.g. 'chat_bubble_outline'
   * wrapper (optional) — String of wrapper e.g. 'span'
   */

  getWrapper(strg) {
    // Make sure wrapper is valid
    return ['span', 'div'].includes(strg.toLowerCase().trim()) ? strg.trim() : 'span';
  }

  render() {
    return (
      <ConditionalWrapper
        condition={this.props.wrapper}
        wrapper={
          (children) => {
            let Node = `${this.getWrapper(this.props.wrapper)}`;
            return (<Node className="icon">{children}</Node>)
          }
        }>
        <i className={`icon-${this.props.icon}`}></i>
      </ConditionalWrapper>
    );
  }
}

export default Icon
