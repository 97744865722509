import React from "react"

function Loadbar(props) {
  return (
    <div className="loadbar">
      <progress max={props.total} value={props.current}></progress>
    </div>
  )
}
export default Loadbar
