import React, { Component } from 'react';
import LocElement from './LocElement';
import CloseButton from './CloseButton';
class Header extends Component
{
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    let classes = ['pageHeader'];
    if (this.props.mode) classes.push(this.props.mode);
    return (
      <header className={classes.join(' ')}>
        <div className="container">
          <div className="headingInstructions">
            {
              this.props.config.headline &&
              (<h1 className="pageHeadline"><LocElement {...this.props.config.headline} /></h1>)
            }
            {
              this.props.config.additionalHeadline &&
              (<h1 className="pageSecHeadline"><LocElement {...this.props.config.additionalHeadline} /></h1>)
            }
            {
              this.props.config.description &&
              (
                <div className="instructions">
                  <ul>
                    {
                      ["what","how","time","hint"].map((key) => {
                        if(!this.props.config.description[key]) return null;
                        return (<li key={key} className={key}><LocElement {...this.props.config.description[key]} /></li>)
                      })
                    }
                  </ul>
                </div>
              )
            }
            {
              this.props.config.textDescription &&
              (
                <div className="description">
                  <p>{<LocElement {...this.props.config.textDescription} />}</p>
                </div>
              )
            }
          </div>
          {
            this.props.config.infoImage &&
            (
              <div className="infoImage">
                <LocElement {...this.props.config.infoImage} />
              </div>
            )
          }
          {
            this.props.isFragebogenSkala &&
            (
              <div className="fragebogen_skala">
                <div className="text left">trifft überhaupt nicht zu</div>
                <div className="skala">
                  <svg viewBox="0 0 310 50">
                    <g id="radios">
                      <g id="radio_1">
                        <path className="cls-1" d="M31,25,19,31V19Z"/>
                        <path className="cls-2" d="M40,1a9,9,0,0,1,9,9V40a9,9,0,0,1-9,9H10a9,9,0,0,1-9-9V10a9,9,0,0,1,9-9H40m0-1H10A10,10,0,0,0,0,10V40A10,10,0,0,0,10,50H40A10,10,0,0,0,50,40V10A10,10,0,0,0,40,0Z"/>
                      </g>
                      <g id="radio_2">
                        <path className="cls-1" d="M81.81,33.19V16.81L98.19,25Z"/>
                        <path className="cls-2" d="M105,1a9,9,0,0,1,9,9V40a9,9,0,0,1-9,9H75a9,9,0,0,1-9-9V10a9,9,0,0,1,9-9h30m0-1H75A10,10,0,0,0,65,10V40A10,10,0,0,0,75,50h30a10,10,0,0,0,10-10V10A10,10,0,0,0,105,0Z"/>
                      </g>
                      <g id="radio_3">
                        <path className="cls-1" d="M144.81,35.19V14.81L165.19,25Z"/>
                        <path className="cls-2" d="M170,1a9,9,0,0,1,9,9V40a9,9,0,0,1-9,9H140a9,9,0,0,1-9-9V10a9,9,0,0,1,9-9h30m0-1H140a10,10,0,0,0-10,10V40a10,10,0,0,0,10,10h30a10,10,0,0,0,10-10V10A10,10,0,0,0,170,0Z"/>
                      </g>
                      <g id="radio_4">
                        <path className="cls-1" d="M207.81,37.19V12.81L232.19,25Z"/>
                        <path className="cls-2" d="M235,1a9,9,0,0,1,9,9V40a9,9,0,0,1-9,9H205a9,9,0,0,1-9-9V10a9,9,0,0,1,9-9h30m0-1H205a10,10,0,0,0-10,10V40a10,10,0,0,0,10,10h30a10,10,0,0,0,10-10V10A10,10,0,0,0,235,0Z"/>
                      </g>
                      <g id="radio_5">
                        <path className="cls-1" d="M270.81,39.19V10.81L299.19,25Z"/>
                        <path className="cls-2" d="M300,1a9,9,0,0,1,9,9V40a9,9,0,0,1-9,9H270a9,9,0,0,1-9-9V10a9,9,0,0,1,9-9h30m0-1H270a10,10,0,0,0-10,10V40a10,10,0,0,0,10,10h30a10,10,0,0,0,10-10V10A10,10,0,0,0,300,0Z"/>
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="text right">trifft<br/>voll und ganz zu</div>
              </div>
            )
          }
          {
            this.props.overlayCloser &&
            (
              <CloseButton onClose={this.props.onClose}/>
            )
          }
        </div>


      </header>
    );
  }
}

export default Header
