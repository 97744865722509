import React, { Component } from "react";
import Overlay from "./Overlay";
import StaticContent from "./../views/StaticContent";

class StaticLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overlaySlug: null
    };
  }

  openStaticContent(e, destination) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ overlaySlug: destination })
  }

  render() {
    return (
      <span
        className="static-link"
        onClick={e => {
          this.openStaticContent(e, this.props.to);
        }}
      >
        {this.props.children}
        {this.state.overlaySlug && (
          <Overlay>
            <StaticContent
              slug={this.state.overlaySlug}
              onClose={() => {
                this.setState({ overlaySlug: null });
              }}
            />
          </Overlay>
        )}
      </span>
    );
  }
}
export default StaticLink;
