import React, { Component, Fragment } from "react";
import uuid from "react-uuid";
import Icon from "./../Icon";
import LocElement from "./../LocElement";
import DataFetcher from "./../../utils/DataFetcher";

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focus: false,
      inputValue: this.props.defaultValue || "",
      inputValid: this.props.validation ? true : false,
      inputFetchValid: this.props.validationByFetch ? true : false,
      type: this.props.type || "text",
      fetchFailureMessage: null
    };
    if (this.props.changeType) this._toggleChangeButtonType = `this.props.changeType|on`;
    this._input = React.createRef();
    this._fallbackId = uuid();
    this._validationByFetchTimeoutId = null;
  }

  get name() {
    return this.props.name;
  }

  get value() {
    return !this.state.inputValid || this.state.inputValue === "" ? null : this.state.inputValue;
  }

  _onChange(e) {
    let state = {
      inputValue: e.target.value,
      inputValid: this.props.validation ? this.props.validation.test(e.target.value) : true
    };
    const _setState = state => {
      this.setState(state, () => {
        if (this.props.onChange) this.props.onChange(e);
      });
    };
    if (this._validationByFetchTimeoutId) clearTimeout(this._validationByFetchTimeoutId);

    if (!state.inputValid) {
      state.fetchFailureMessage = null;
      _setState(state);
      return e;
    }

    if (this.props.validationByFetch) {
      let { url, delay } = this.props.validationByFetch;
      if (!url) {
        url = this.props.validationByFetch;
        delay = 100;
      }
      _setState(state);
      this._validationByFetchTimeoutId = setTimeout(
        () =>
          DataFetcher.fetch(url, { proposal: state.inputValue }).then(response => {
            state.inputFetchValid = response.success;
            if (!state.inputFetchValid) {
              state.fetchFailureMessage = response.msg;
              state.inputValid = false;
            } else state.fetchFailureMessage = null;
            _setState(state);
          }),
        delay
      );
    } else _setState(state);
  }

  componentDidMount() {
    if (this.props.focused) {
      setTimeout(() => {
        this._input.current.focus();
      }, 500);
    }
  }

  render() {
    return (
      <Fragment>
        <div className={`ll-formelement ll-formelement-input ${this.state.focus ? "focus" : ""}`} key={`${this.props.id || this._fallbackId}-wrapper`}>
          <span className="top"></span>
          <span className="bottom"></span>
          <input
            ref={this._input}
            key={`${this.props.id || this._fallbackId}-input`}
            id={this.props.id || this._fallbackId}
            name={this.props.name}
            type={this.state.type}
            autoComplete={this.props.autocomplete || "off"}
            placeholder={this.state.focus ? "" : this.props.placeholder}
            value={this.state.inputValue}
            onFocus={() => {
              this.setState({ focus: true });
            }}
            onBlur={() => {
              this.setState({ focus: false });
            }}
            onChange={this._onChange.bind(this)}
          />
          {this.props.validationValidIcon && this.state.inputValid && this.state.inputValue !== "" && (
            <div className="iconHolder valid">
              <Icon icon={this.props.validationValidIcon} />
            </div>
          )}
          {this.props.validationFailureIcon && !this.state.inputValid && this.state.inputValue !== "" && (
            <div className="iconHolder invalid">
              <Icon icon={this.props.validationFailureIcon} />
            </div>
          )}
          {this.props.changeType && (
            <button
              className={`iconHolder changeType ${this._toggleChangeButtonType.split("|")[1]}`}
              onClick={() => {
                let oldType = this.state.type;
                let [newType, toggler] = this._toggleChangeButtonType.split("|");
                this._toggleChangeButtonType = `${oldType}|${toggler === "on" ? "off" : "on"}`;
                this.setState({ type: newType });
              }}
            >
              <Icon icon="remove_red_eye" />
            </button>
          )}
        </div>
        {this.state.inputValue !== "" && this.props.validationByFetch && !this.props.inputFetchValid && this.state.fetchFailureMessage !== null && <p className="fail"><LocElement {...this.props.validationByFetchFailureHint[this.state.fetchFailureMessage]} /></p>}
        {this.state.inputValue !== "" && this.props.validationFailureHint && !this.state.inputValid && this.state.fetchFailureMessage === null && <p className="fail"><LocElement {...this.props.validationFailureHint} /></p>}
      </Fragment>
    );
  }
}

export default Input;
