import React, { Component } from 'react';
import Icon from './Icon';



class CloseButton extends Component
{
  constructor(props) {
    super(props);
    this.state = {};
  }

  closeOverlay() {
    this.setState({ overlayIsOpen: false }, () => {
      this.props.onClose();
    });
  }


  render() {

    return (
      <button className="overlayCloser" onClick={(e) => {this.closeOverlay()}} >
        <Icon icon="close" wrapper="span" />
      </button>
    );
  }
}
export default CloseButton
