class DataFetcher {
  constructor(){
    this.bridgeUrl = `${process.env.REACT_APP_BRIDGE_URL}/pwa/`;
    this._quiet = false;
    this._log(`online -> ${this.bridgeUrl}`);
  }

  _log(msg){
    !this._quiet && console.log("DataFetcher", msg, Date.now());
  }

  fetch(endpoint, body){
    this._log(`fetch -> ${endpoint} with ${JSON.stringify(body)}`);
    console.log(`${this.bridgeUrl}${endpoint}`);
    return fetch(
        `${this.bridgeUrl}${endpoint}`,
        {
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(r => r.json())
      .catch(e => console.error(e))
  }
}

export default new DataFetcher();
