import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Hub from './views/Hub';
import SplashScreen from './views/SplashScreen';
import Login from './views/Login';
import Welcome from './views/Welcome';
import Registration from './views/Registration';
import Footer from './components/Footer';
import PrivateRoute from './components/routing/PrivateRoute';

function App() {

// NOTE: deprecate after wedding
  const branding = {
    logo: {
      url: "media/Logo_IHK_Mittelfranken.gif",
      alt: "Logo IHK Mittelfranken"
    }
  };

  return (
    <div className="App">
      <Switch>
        <Route path="/" component={Welcome} exact />
        <Route path="/registration" component={Registration} exact />
        <Route path="/login" component={Login} exact />
        <PrivateRoute path="/hub" component={Hub} exact />
      </Switch>
      <Footer isBranded={branding}/>
    </div>
  );
}

export default SplashScreen(App);
