/**
 * Use this wrapper if for example a component
 * should be wrapped inside a NavLink only if
 * a URL is provided
 */
const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;

module.exports = {
  ConditionalWrapper: ConditionalWrapper,
  random: (min, max) => {
    return min + Math.random() * (max - min);
  }
}
