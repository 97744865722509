import Quizmaster from './Quizmaster';
import User from './../User';
import DataPersister from './../utils/DataPersister';

class Survey extends Quizmaster
{
  constructor(props) {
    super(props);

    this.data = User.getDataEntry('flow.survey');
    this.content = DataPersister.getItem('flow').survey;
    this._answers = User.getDataEntry('answers.survey', {});
    this._timeToSolve = User.getDataEntry('timeToSolve.survey', {});

    this.state = {
      currentQuestion: 0,
      maxQuestions: this.data.length,
      mcpMode: "tracker",
      testMode: "survey"
    }
  }

  componentDidMount() {
    this.setMCPMode(this.state.mcpMode);
  }

  updateAnswers(data) {
    super.updateAnswers(data);
    User.setDataEntry('answers.survey', this._answers);
    User.setDataEntry('timeToSolve.survey', this._timeToSolve);
  }

  onTestFinish() {
    User.setDataEntry('flags.surveyFinished', true);
    super.onTestFinish();
  }

  proceed() {
    this._interimScreenFlag = false;
    super.proceed();
  }
}

export default Survey
