import Quizmaster from './Quizmaster';
import Localizator from './../utils/Localizator';
import User from './../User';
import DataPersister from './../utils/DataPersister';
import { AnimationTypes } from './../components/assistant/Assistant';

class Questionnaire extends Quizmaster
{
  constructor(props) {
    super(props);

    this.data = User.getDataEntry('flow.questionnaire');
    this.content = DataPersister.getItem('flow').questionnaire;
    this._answers = User.getDataEntry('answers.questionnaire', {});
    this._timeToSolve = User.getDataEntry('timeToSolve.questionnaire', {});
    this._userHasStartedTest = Object.keys(this._answers).length !== 0;

    this.state = {
      currentQuestion: this._userHasStartedTest ? 0 : -1,
      interimScreenFlag: this._userHasStartedTest ? false : true,
      maxQuestions: this.data.length,
      mcpMode: "tracker",
      testMode: "fragebogen",
      showIntro: false
    }

    this._interimScreenFlag = this.state.interimScreenFlag;

    let welcome = this._userHasStartedTest ? [] : [{
      content: {
        bigSpeechBubble: Localizator.getLocale('zwischenscreen_vor_fragebogen.speechbubble_1'),
        smallSpeechBubble: Localizator.getLocale('zwischenscreen_vor_fragebogen.speechbubble_2'),
        assistantMode: AnimationTypes.WINKEN,
        addAssistantClasses: null
    }
    }];

    this._interimScreens = [].concat(welcome,[
      {
        content: {
          bigSpeechBubble: Localizator.getLocale('zwischenscreen_nach_fragebogen_s3.speechbubble_1'),
          smallSpeechBubble: Localizator.getLocale('zwischenscreen_nach_fragebogen_s3.speechbubble_2'),
          assistantMode: AnimationTypes.TWIRL,
          addAssistantClasses: null
        },
        position: 2
      },
      {
        content: {
          bigSpeechBubble: Localizator.getLocale('zwischenscreen_nach_fragebogen_s6.speechbubble_1'),
          smallSpeechBubble: Localizator.getLocale('zwischenscreen_nach_fragebogen_s6.speechbubble_2'),
          assistantMode: AnimationTypes.WINKEN,
          addAssistantClasses: null
        },
        position: 5
      }
    ]);
  }

  updateAnswers(data) {
    super.updateAnswers(data);
    User.setDataEntry('answers.questionnaire', this._answers);
    User.setDataEntry('timeToSolve.questionnaire', this._timeToSolve);
  }

  onTestFinish() {
    User.setDataEntry('flags.questFinished', true);
    super.onTestFinish();
  }

  proceed() {
    //interimscreenflag bestimmen und setzen
    let iscreen = this._interimScreens.find(i => i.position === this.state.currentQuestion);
    if(iscreen) this._interimScreenFlag = true;
    super.proceed();
  }
}

export default Questionnaire
