import React from "react";
import FormElementView from "./FormElementView";
import LlFeInput from "./../components/formelements/Input";
import LlFeDropDown from "./../components/formelements/DropDown";
import LlFeCheckbox from "./../components/formelements/CheckBox";
import { Group as LlFeGroup, ValidationTypes as LlFeGroupValidationTypes } from "./../components/formelements/Group";
import uuid from "react-uuid";
import User from "./../User";
import Localizator from "./../utils/Localizator";
import { AnimationTypes } from "./../components/assistant/Assistant";

class Registration extends FormElementView {
  constructor(props) {
    super(props);
    this.state = Object.assign({}, this.state, {
      header: {
        headline: {
          german: "Anmeldung",
          english: "Registration"
        }
      },
      regNotice: true
    });

    this._screens = [
      (() => {
        let o = {
          _id: uuid(),
          _cbId: uuid(),
          key: "email",
          cbKey: "noemail",
          givenValue: null,
          intro: { german: "Bist du bereits registriert? Dann klicke #[link;hier;/login]" },
          label: { german: "E-Mail-Adresse" },
          cbLabel: { german: "Ich habe keine E-Mail-Adresse und weiß, dass ich ohne E-Mail-Adresse mein vergessenes Passwort nicht zurück&shy;setzen kann." }
        };
        o.ele = this._createFormFragment(
          o,
          <LlFeGroup ref={this._cv} onChange={this._currentValueChanged.bind(this)} className="ll-formgroup" validationType={LlFeGroupValidationTypes.AT_LEAST_ONE}>
            <LlFeInput
              type="email"
              name={o.key}
              key={o._id}
              id={o._id}
              placeholder="…"
              /* eslint-disable */
              validation={/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/}
              /* eslint-enable */
              validationValidIcon={"check"}
              focused={true}
            />
            <LlFeCheckbox key={o._cbId} id={o._cbId} name={o.cbKey} label={o.cbLabel} value="Y" />
          </LlFeGroup>
        );
        return o;
      })(),
      (() => {
        let o = {
          _id: uuid(),
          key: "username",
          givenValue: null,
          failureHint: { german: "<strong>Hinweis:</strong><br/>Dein Benutzername darf aus Buchstaben, Zahlen und Unterstrich bestehen.<br />Es sind keine Leerzeichen erlaubt." },
          fetchFailureHint: {
            USERNAME_ALREADY_USED: { german: "Username bereits vergeben …" }
          },
          label: { german: "Benutzername" }
        };
        o.ele = this._createFormFragment(
          o,
          <LlFeInput
            name={o.key}
            key={o._id}
            id={o._id}
            ref={this._cv}
            placeholder="…"
            onChange={this._currentValueChanged.bind(this)}
            validation={/^[A-Za-z0-9_]+$/i}
            validationFailureHint={o.failureHint}
            validationByFetch={{ url: "registration/check-username", delay: 300 }}
            validationByFetchFailureHint={o.fetchFailureHint}
            validationValidIcon={"check"}
            focused={true}
          />
        );
        return o;
      })(),
      (() => {
        let o = {
          _id: uuid(),
          key: "password",
          givenValue: null,
          label: { german: "Passwort" },
          failureHint: { german: "<strong>Hinweis:</strong><br />Dein Passwort muss aus mindestens 8 Zeichen bestehen" }
        };
        o.ele = this._createFormFragment(
          o,
          <LlFeInput
            name={o.key}
            key={o._id}
            id={o._id}
            ref={this._cv}
            type="password"
            onChange={this._currentValueChanged.bind(this)}
            validation={/^.{8,}$/}
            validationFailureHint={o.failureHint}
            changeType={"text"}
            focused={true}
          />
        );
        return o;
      })(),
      {
        // Screen in the middle that tells the user why he must enter his personal information
        isInterim: true,
        regNoticeScreen: {
          bigSpeechBubble: Localizator.getLocale("zwischenscreen_1_angabe_persoenliche_daten.speechbubble_1"),
          smallSpeechBubble: Localizator.getLocale("zwischenscreen_1_angabe_persoenliche_daten.speechbubble_2"),
          assistantMode: AnimationTypes.WINKEN,
          addAssistantClasses: null
        }
      },
      (() => {
        let o = {
          _id: uuid(),
          key: "firstname",
          givenValue: null,
          label: { german: "Vorname" }
        };
        o.ele = this._createFormFragment(
          o,
          <LlFeInput
            name={o.key}
            key={o._id}
            id={o._id}
            ref={this._cv}
            placeholder="…"
            onChange={this._currentValueChanged.bind(this)}
            focused={true}
          />
        );
        return o;
      })(),
      (() => {
        let o = {
          _id: uuid(),
          key: "lastname",
          givenValue: null,
          label: { german: "Nachname" }
        };
        o.ele = this._createFormFragment(
          o,
          <LlFeInput
            name={o.key}
            key={o._id}
            id={o._id}
            ref={this._cv}
            placeholder="…"
            onChange={this._currentValueChanged.bind(this)}
            focused={true}
          />
        );
        return o;
      })(),
      (() => {
        let o = {
          _id: uuid(),
          key: "sex",
          givenValue: null,
          label: { german: "Geschlecht" }
        };
        o.ele = this._createFormFragment(
          o,
          <LlFeDropDown
            name={o.key}
            key={o._id}
            id={o._id}
            ref={this._cv}
            onChange={this._currentValueChanged.bind(this)}
            options={[
              { label: { german: "Bitte auswählen" }, selected: true, disabled: true },
              { label: { german: "männlich" }, value: "m" },
              { label: { german: "weiblich" }, value: "f" },
              { label: { german: "divers" }, value: "d" }
            ]}
            focused={true}
          />
        );
        return o;
      })(),
      (() => {
        let o = {
          _id: uuid(),
          key: "age",
          givenValue: null,
          label: { german: "Alter" }
        };
        let itms = [{ label: { german: "Bitte auswählen" }, selected: true, disabled: true }].concat(['< 13', 13, 14, 15, 16, 17, 18, 19, 20, '> 20'].map((i, idx) => ({ label: {german: i}, value: (typeof i === "string" ? (idx === 0 ? 12 : 21) : i)})));
        o.ele = this._createFormFragment(
          o,
          <LlFeDropDown
            name={o.key}
            key={o._id}
            id={o._id}
            ref={this._cv}
            onChange={this._currentValueChanged.bind(this)}
            options={itms}
            focused={true}
          />
        );
        return o;
      })(),
      (() => {
        let o = {
          _id: uuid(),
          key: "schoolyear",
          givenValue: null,
          label: { german: "Klasse" }
        };
        let itms = [{ label: { german: "Bitte auswählen" }, disabled: true }].concat([7, 8, 9, 10, 11, 12, 13, "keine Auswahl zutreffend"].map(i => ({ label: {german: i}, value: (typeof i === "string" ? 14 : i)})));
        o.ele = this._createFormFragment(
          o,
          <LlFeDropDown
            name={o.key}
            key={o._id}
            id={o._id}
            ref={this._cv}
            onChange={this._currentValueChanged.bind(this)}
            options={itms}
            focused={true}
          />
        );
        return o;
      })(),
      (() => {
        let o = {
          _id: uuid(),
          key: "region",
          givenValue: null,
          label: { german: "Bundesland" }
        };
        let itms = [{ label: { german: "Bitte auswählen" }, selected: true, disabled: true }].concat(
          [
            ["BW", "Baden-Württemberg"],
            ["BY", "Bayern"],
            ["BE", "Berlin"],
            ["BB", "Brandenburg"],
            ["HB", "Bremen"],
            ["HH", "Hamburg"],
            ["HE", "Hessen"],
            ["MV", "Mecklenburg-Vorpommern"],
            ["NI", "Niedersachsen"],
            ["NW", "Nordrhein-Westfalen"],
            ["RP", "Rheinland-Pfalz"],
            ["SL", "Saarland"],
            ["SN", "Sachsen"],
            ["ST", "Sachsen-Anhalt"],
            ["SH", "Schleswig-Holstein"],
            ["TH", "Thüringen"]
          ].map(x => ({ label: { german: x[1] }, value: x[0] }))
        );
        o.ele = this._createFormFragment(
          o,
          <LlFeDropDown
            name={o.key}
            key={o._id}
            id={o._id}
            ref={this._cv}
            onChange={this._currentValueChanged.bind(this)}
            options={itms}
            focused={true}
          />
        );
        return o;
      })(),
      () => {
        let o = {
          _id: uuid(),
          key: "graduation",
          givenValue: null,
          label: { german: "(Angestrebter) Schulabschluss" }
        };
        let itms = [{ label: { german: "Bitte auswählen" }, selected: true, disabled: true }];
        let region = this._screens.find(x => x && typeof x === "object" && x.key === "region");
        region = region ? region.givenValue : "LL_NONE";
        let graduationItems = {
          LL_NONE: [],
          BW: [
            ["HS", "Hauptschulabschluss"],
            ["RS", "Realschulabschluss"],
            ["GY", "Allgemeine Hochschulreife"],
            ["BG", "Fachhochschulreife"],
            ["FO", "fachgebundene Hochschulreife"]
          ].map(x => ({ label: { german: x[1] }, value: x[0] })),
          BY: [
            ["HS", "Abschluss der Mittelschule"],
            ["RS", "Mittlerer Schulabschluss"],
            ["GY", "Allgemeine Hochschulreife"],
            ["BG", "Fachhochschulreife"],
            ["FO", "fachgebundene Hochschulreife"]
          ].map(x => ({ label: { german: x[1] }, value: x[0] })),
          BE: [
            ["HS", "(Erweiterte) Berufsbildungsreife"],
            ["RS", "Mittlerer Schulabschluss"],
            ["GY", "Allgemeine Hochschulreife"],
            ["BG", "Fachhochschulreife"],
            ["FO", "fachgebundene Hochschulreife"]
          ].map(x => ({ label: { german: x[1] }, value: x[0] })),
          BB: [
            ["HS", "(Erweiterte) Berufsbildungsreife"],
            ["RS", "Fachoberschulreife"],
            ["GY", "Allgemeine Hochschulreife"],
            ["BG", "Fachhochschulreife"]
          ].map(x => ({ label: { german: x[1] }, value: x[0] })),
          HB: [
            ["HS", "(Erweiterte) Berufsbildungsreife"],
            ["RS", "Mittlerer Schulabschluss"],
            ["GY", "Allgemeine Hochschulreife"],
            ["BG", "Fachhochschulreife"],
            ["FO", "fachgebundene Hochschulreife"]
          ].map(x => ({ label: { german: x[1] }, value: x[0] })),
          HH: [
            ["HS", "Erster Allgemeinbildender Schulabschluss"],
            ["RS", "Mittlerer Schulabschluss"],
            ["GY", "Allgemeine Hochschulreife"],
            ["BG", "Fachhochschulreife"],
            ["FO", "fachgebundene Hochschulreife"]
          ].map(x => ({ label: { german: x[1] }, value: x[0] })),
          HE: [
            ["HS", "(Qualifizierender) Hauptschulabschluss"],
            ["RS", "(Qualifizierender) Realschulabschluss"],
            ["GY", "Allgemeine Hochschulreife"],
            ["BG", "Fachhochschulreife"]
          ].map(x => ({ label: { german: x[1] }, value: x[0] })),
          MV: [
            ["HS", "Berufsreife"],
            ["RS", "Mittlere Reife"],
            ["GY", "Allgemeine Hochschulreife"],
            ["BG", "Fachhochschulreife"]
          ].map(x => ({ label: { german: x[1] }, value: x[0] })),
          NI: [
            ["HS", "Hauptschulabschluss"],
            ["RS", "Realschulabschluss"],
            ["GY", "Allgemeine Hochschulreife"],
            ["BG", "Fachhochschulreife"],
            ["FO", "fachgebundene Hochschulreife"]
          ].map(x => ({ label: { german: x[1] }, value: x[0] })),
          NW: [
            ["HS", "Hauptschulabschluss"],
            ["RS", "Fachoberschulreife"],
            ["GY", "Allgemeine Hochschulreife"],
            ["BG", "Fachhochschulreife"],
            ["FO", "fachgebundene Hochschulreife"]
          ].map(x => ({ label: { german: x[1] }, value: x[0] })),
          RP: [
            ["HS", "Berufsreife"],
            ["RS", "Qualifizierter Sekundarabschluss I"],
            ["GY", "Allgemeine Hochschulreife"],
            ["BG", "Fachhochschulreife"],
            ["FO", "fachgebundene Hochschulreife"]
          ].map(x => ({ label: { german: x[1] }, value: x[0] })),
          SL: [
            ["HS", "Hauptschulabschluss"],
            ["RS", "Mittlerer Bildungsabschluss"],
            ["GY", "Allgemeine Hochschulreife"],
            ["BG", "Fachhochschulreife"]
          ].map(x => ({ label: { german: x[1] }, value: x[0] })),
          SN: [
            ["HS", "(Qualifizierter) Hauptschulabschluss"],
            ["RS", "(Erweiterter) Realschulabschluss"],
            ["GY", "Allgemeine Hochschulreife"],
            ["BG", "Fachhochschulreife"]
          ].map(x => ({ label: { german: x[1] }, value: x[0] })),
          ST: [
            ["HS", "(Qualifizierender) Hauptschulabschluss"],
            ["RS", "Realschulabschluss"],
            ["GY", "Allgemeine Hochschulreife"],
            ["BG", "Fachhochschulreife"]
          ].map(x => ({ label: { german: x[1] }, value: x[0] })),
          SH: [
            ["HS", "Erster Allgemeinbildender Schulabschluss"],
            ["RS", "Mittlerer Schulabschluss"],
            ["GY", "Allgemeine Hochschulreife"],
            ["BG", "Fachhochschulreife"],
            ["FO", "fachgebundene Hochschulreife"]
          ].map(x => ({ label: { german: x[1] }, value: x[0] })),
          TH: [
            ["HS", "(Qualifizierender) Hauptschulabschluss"],
            ["RS", "Realschulabschluss"],
            ["GY", "Allgemeine Hochschulreife"],
            ["BG", "Fachhochschulreife"]
          ].map(x => ({ label: { german: x[1] }, value: x[0] }))
        };
        itms = itms.concat(graduationItems[region]);
        o.ele = this._createFormFragment(
          o,
          <LlFeDropDown
            name={o.key}
            key={o._id}
            id={o._id}
            ref={this._cv}
            onChange={this._currentValueChanged.bind(this)}
            options={itms}
            focused={true}
          />
        );
        return o;
      },
      (() => {
        let o = {
          _id: uuid(),
          key: "acknowledgement",
          givenValue: null,
          label: { german: "Datenschutz" },
          cbLabel: { german: "Ich habe die #[staticLink;Datenschutzerklärung;datenschutz] gelesen<br />und akzeptiere diese." }
        };
        o.ele = this._createFormFragment(
          o,
          <LlFeCheckbox
            key={o._id}
            id={o._id}
            name={o.key}
            ref={this._cv}
            onChange={this._currentValueChanged.bind(this)}
            label={o.cbLabel}
            value="Y"
            focused={true}
          />
        );
        return o;
      })()
    ];
  }

  _finishedWithView() {
    let vals = super._finishedWithView();
    User.register(vals).then(() => this.setState({ currentScreen: -1, mcpDisabled: true }))
  }
}

export default Registration;
