import React, { Component } from "react"
import RobotSVG from "./modules/RobotSVG"
import { default as AnimatedPNG } from "./modules/AnimatedPNG"

export const AnimationTypes = {
  IDLE: "idle",
  ROLLIN: "rollin",
  TWIRL: "twirl",
  WINKEN: "winken",
  HINUNDHER: "hinundher",
  GATE_SQUARE: "gate_square",
  GATE_RING: "gate_ring",
  EYES_GLOW: "eyes-glow"
}

class Assistant extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: props.mode || AnimationTypes.IDLE
    }
    this._robot = React.createRef()
  }

  setIdleAndReport() {
    if (this.state.mode !== AnimationTypes.IDLE)
      return this.setState({ mode: AnimationTypes.IDLE }, () => {
        this.props.reportStatus && this.props.reportStatus({ status: AnimationTypes.IDLE })
      })
    this.props.reportStatus && this.props.reportStatus({ status: AnimationTypes.IDLE })
  }

  componentDidMount() {
    // If assistant should start with idle report on start (welcome show bubbles)
    if (this.state.mode === AnimationTypes.IDLE) {
      window.setTimeout(() => {
        return this.setIdleAndReport()
      }, 10)
    }
  }

  placeAssistantByMode(mode) {
    let props = {
      ref: this._robot,
      onFinish: this.setIdleAndReport.bind(this),
      id: mode
    }

    if (mode === AnimationTypes.IDLE || Object.values(AnimationTypes).indexOf(mode) === -1) return null
    return <AnimatedPNG {...props} />
  }

  render() {
    let assistentCSSClasses = ["assistant", this.state.mode]
    let classes = this.props.animationClasses ? assistentCSSClasses.concat(this.props.animationClasses) : assistentCSSClasses
    return (
      <div className={classes.join(" ")}>
        <div className="holder">
          <RobotSVG />
          {this.placeAssistantByMode(this.state.mode)}
        </div>
      </div>
    )
  }
}

export default Assistant
